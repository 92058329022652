import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store/store'
import { BetaAPIMutation, BetaObject } from '../utils/interfaces/APIModels'

// Proxy Base URL
const baseURL = process.env.REACT_APP_PROXY_FUNCTION_BASE_URL

export const proxyAPIData = createApi({
	reducerPath: 'proxyAPIData',
	baseQuery: fetchBaseQuery({
		prepareHeaders: (headers, { getState }) => {
			const accessToken = (getState() as RootState).RootReducer
				.accessTokenReducer.value
			const partnerID = (getState() as RootState).RootReducer
				.partnerIDToUseReducer.value

			headers.set(
				'x-functions-key',
				process.env.REACT_APP_API_PROXY_FUNCTION_KEY + ''
			)
			headers.append('authorization', accessToken)
			headers.set('PartnerID', partnerID + '')
			return headers
		},
		baseUrl: baseURL,
	}),
	refetchOnReconnect: true,

	endpoints: (build) => ({
		// GET Call - Pass in a string and return encrypted object or string
		PostAndRetrieveData: build.mutation<BetaObject | string, string>({
			query: (queryURL) => ({
				url: queryURL,
				method: 'POST',
			}),
		}),

		// POST Call - Pass a encrypted object to be added, deleted or updated on DB
		PostBetaObjectWithoutRefetch: build.mutation<
			BetaObject | undefined | null,
			BetaAPIMutation
		>({
			query: (apiMutation) => ({
				url: apiMutation.QueryParam,
				method: 'POST',
				body: apiMutation.BetaObject,
			}),
		}),

		// POST: Custom backend function to get specific objects (Mainly used for Order MGT and User MGT functions)
		PostBetaObjectAndReturnData: build.mutation<
			BetaObject,
			{ url: string; dataObject: BetaObject }
		>({
			query: ({ url, dataObject }) => ({
				url,
				method: 'POST',
				body: dataObject,
			}),
		}),

		//POST: Get Order Summary
		PostBetaObjectOrderSummary: build.mutation<BetaObject, BetaObject>({
			query: (summaryReq) => ({
				url: 'orders/summary',
				method: 'POST',
				body: summaryReq,
			}),
		}),

		//POST: Add new order
		PostBetaObjectAddOrder: build.mutation<BetaObject, BetaObject>({
			query: (addOrderReq) => ({
				url: 'orders',
				method: 'POST',
				body: addOrderReq,
			}),
		}),

		GetTenantProvisioningConfig: build.mutation<BetaObject, string>({
			query: (customerID) =>
				`GetTenantProvisioningConfig?customerID=${customerID}`,
		}),

		GetSDADDIStatus: build.mutation<BetaObject, string>({
			query: (serviceID) => `GetSDADDIStatus?serviceID=${serviceID}`,
		}),

		TeamsUserManagementPrechecks: build.mutation<BetaObject, string>({
			query: (tenantID) => `TeamsUserManagementPrechecks?tenantID=${tenantID}`,
		}),

		CheckActiveTeamsDROrderSKUsForCustomer: build.mutation<BetaObject, string>({
			query: (customerID) =>
				`CheckActiveTeamsDROrderSKUsForCustomer?customerID=${customerID}`,
		}),

		StartSDAConfiguration: build.mutation<string, BetaAPIMutation>({
			query: (apiMutation) => ({
				url: `${apiMutation.QueryParam}`,
				method: 'POST',
				body: apiMutation.BetaObject,
			}),
		}),

		GetServiceManagementReportAndStatusForCustomer: build.mutation<
			string,
			string
		>({
			query: (customerID) =>
				`GetServiceManagementReportAndStatusForCustomer?customerID=${customerID}`,
		}),

		getMSLicensesForHaloUsers: build.mutation<BetaObject, BetaObject>({
			query: (GetMSLicensesForHaloUsersModel) => ({
				url: `GetMSLicensesForHaloUsers`,
				method: 'POST',
				body: GetMSLicensesForHaloUsersModel,
			}),
		}),

		GetProxyObject: build.query<BetaObject, string>({
			query: (queryParams) => `GetV2?Params=${queryParams}`,
		}),

		PublishServiceBusMessage: build.mutation<void, BetaObject>({
			query: (publishServiceBusMessageModel) => ({
				url: `PublishServiceBusMessage`,
				method: 'POST',
				body: publishServiceBusMessageModel,
			}),
		}),

		PerformTeamsUserManagementPrechecks: build.mutation<BetaObject, string>({
			query: (tenantID) => `TeamsUserManagementPrechecks?tenantID=${tenantID}`,
		}),

		CheckIfCustomerHasProduct: build.mutation<BetaObject, string>({
			query: (params) => `CheckIfCustomerHasProduct?${params}`,
		}),
	}),
})

export const {
	usePostAndRetrieveDataMutation,
	usePostBetaObjectWithoutRefetchMutation,
	usePostBetaObjectAndReturnDataMutation,
	usePostBetaObjectOrderSummaryMutation,
	useCheckIfCustomerHasProductMutation,
	usePostBetaObjectAddOrderMutation,
	useGetTenantProvisioningConfigMutation,
	useCheckActiveTeamsDROrderSKUsForCustomerMutation,
	useStartSDAConfigurationMutation,
	useGetServiceManagementReportAndStatusForCustomerMutation,
	useGetMSLicensesForHaloUsersMutation,
	useGetProxyObjectQuery,
	usePublishServiceBusMessageMutation,
	useGetSDADDIStatusMutation,
	useTeamsUserManagementPrechecksMutation,
	usePerformTeamsUserManagementPrechecksMutation,
} = proxyAPIData
