import { Box, Divider, Tab, Tabs, Typography } from '@mui/material'
import {
	DDIDisplay,
	DDIRangeDisplay,
} from '../../../../../utils/interfaces/ComponentModels'
import { useState } from 'react'

import {
	AddressMap,
	DDI,
	DDIStatus,
} from '../../../../../utils/interfaces/DBModels'
import { TabPanel } from './TabPanel'
import TNDisplay from './TNDisplay'
import TNRangeDisplay from './TNRangeDisplay'

const TNOverviewDisplay = ({
	ddiDisplay,
	ddiRangeDisplay,
	ddiList,
	ddiStatusList,
	addressMapList,
	numberOfRows,
	getTNs,
	handleFilterData,
	maxPageNo,
	totalRecords,
	filterLoading,
	handleFilterDDIRangesData,
	ddiRangesMaxPageNo,
	ddiRangesTotalRecords,
}: {
	ddiDisplay: DDIDisplay[]
	ddiRangeDisplay: DDIRangeDisplay[]
	ddiList: DDI[]
	ddiStatusList: DDIStatus[]
	addressMapList: AddressMap[]
	numberOfRows: number
	getTNs: () => Promise<void>
	handleFilterData: (pageNumber: number) => Promise<void>
	maxPageNo: number
	totalRecords: number
	filterLoading: boolean
	handleFilterDDIRangesData: (pageNumber: number) => Promise<void>
	ddiRangesMaxPageNo: number
	ddiRangesTotalRecords: number
}) => {
	//useState
	const [tabValue, setTabValue] = useState(0)

	//handle tab value change
	const handleTabChange = (event: React.SyntheticEvent, newvalue: number) => {
		setTabValue(newvalue)
	}

	return (
		<Box className='tn-overview-container'>
			{/* Order status filter */}
			<Box className='tn-overview-filters'>
				<Box className='description'>
					<Typography component='p'>
						Manage your TNs, update emergency address details.
					</Typography>
				</Box>
			</Box>

			{/* tab panel - Documents and FAQs */}
			<Box className='support-tab-container'>
				<Tabs value={tabValue} onChange={handleTabChange}>
					<Tab label='TNs' />
					<Tab label='TN Ranges' />
				</Tabs>

				{/* Documents tab */}
				<TabPanel index={0} value={tabValue}>
					<TNDisplay
						ddiDisplay={ddiDisplay}
						ddiList={ddiList}
						ddiStatusList={ddiStatusList}
						addressMapList={addressMapList}
						numberOfRows={numberOfRows}
						getTNs={getTNs}
						handleFilterData={handleFilterData}
						maxPageNo={maxPageNo}
						totalRecords={totalRecords}
						filterLoading={filterLoading}
					/>
				</TabPanel>
				<TabPanel index={1} value={tabValue}>
					<TNRangeDisplay
						ddiRangeDisplay={ddiRangeDisplay}
						ddiList={ddiList}
						addressMapList={addressMapList}
						numberOfRows={numberOfRows}
						getTNs={getTNs}
						handleFilterDDIRangesData={handleFilterDDIRangesData}
						filterLoading={filterLoading}
						ddiRangesMaxPageNo={ddiRangesMaxPageNo}
						ddiRangesTotalRecords={ddiRangesTotalRecords}
					/>
				</TabPanel>
			</Box>

			<Divider />
		</Box>
	)
}

export default TNOverviewDisplay
