import {
	Box,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import AvatarBox from '../../displays/AvatarBox/AvatarBox'
import './SideMenu.scss'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { MenuCategories, Roles } from '../../../../utils/enums/enums'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { initialRouteList } from '../../../../utils/constants/constants'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import {
	hexToRgba,
	toAlphaString,
	toBetaString,
} from '../../../../utils/helperFunctions/helperFunctions'
import { useCheckIfCustomerHasProductMutation } from '../../../../services/proxyAPIData'
import { ErrorHandling } from '../../../../utils/customHooks/ErrorHandling/ErrorHandling'
import { useAppDispatch } from '../../../../store/hooks'
import { setShowTeamsUMPage } from '../../../../store/reducers/reducers'

export const SideMenu = ({
	isCollapsed,
	toggleSideMenu,
	isSmallerScreen,
}: {
	isCollapsed: boolean
	toggleSideMenu: any
	isSmallerScreen: boolean
}) => {
	// Global variables
	const loggedInUserPermissions = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const loggedInRoleAndPermissions = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)
	const logoURL = useSelector(
		(state: RootState) => state.RootReducer.logoReducer.value
	)
	const dispatch = useAppDispatch()

	const teamsUMProductID = 'TEAMSUM'
	const [checkIfCustomerHasProduct] = useCheckIfCustomerHasProductMutation()
	const addErrorLog = ErrorHandling()

	// Theme
	const theme = useTheme()
	const menuItemHover = hexToRgba(theme.palette.primary.main, 0.2)

	// General
	const location = useLocation()
	const navigate = useNavigate()

	// Auth0
	const { isAuthenticated, user, logout } = useAuth0()

	// Display constant
	const [menuItemList, setMenuItemList] = useState(initialRouteList)
	const [madeCheckProductCall, setMadeCheckProductCall] = useState(false)
	const showTeamsUMItem = useRef(false)

	// Use Effect 1: Check whether menu item is active or not
	useEffect(() => {
		if (!madeCheckProductCall && isAuthenticated) {
			setMadeCheckProductCall(true)
			checkProductsForCustomer()
		}
		// Change menu item display
		handleMenuItemChange()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, loggedInUser])

	const checkProductsForCustomer = async () => {
		if (
			loggedInRoleAndPermissions.find((x) => x.RoleID === Roles.CustomerAdmin)
		) {
			var _showTeamsUMItem = false
			// check if customer logged in and if they have the Teams UM Product
			//If so, show Teams UM Page
			var encCustomerID = toBetaString(loggedInUser?.customerID + '')
			var encProductID = toBetaString(teamsUMProductID)
			var checkIfCustomerHasTeamsUMProductError = ''

			var checkIfCustomerHasTeamsUMProductResp =
				await checkIfCustomerHasProduct(
					`customerID=${encCustomerID}&productID=${encProductID}`
				)
					.unwrap()
					.catch((error) => {
						checkIfCustomerHasTeamsUMProductError = JSON.stringify(error)
					})

			var decryptedCheckIfCustomerHasTeamsUMProductResp = await toAlphaString(
				checkIfCustomerHasTeamsUMProductResp?.Content + ''
			)

			try {
				_showTeamsUMItem = JSON.parse(
					decryptedCheckIfCustomerHasTeamsUMProductResp
				) as boolean
			} catch (error) {
				_showTeamsUMItem = false

				await addErrorLog({
					ActionPerformed:
						'Generic Portal Check if Customer has Teams UM Product',
					ErrorInfo: `${JSON.stringify(
						error
					)} ${checkIfCustomerHasTeamsUMProductError}`,
				})
			}

			dispatch(setShowTeamsUMPage(_showTeamsUMItem))
			showTeamsUMItem.current = _showTeamsUMItem
			handleMenuItemChange()
		}
	}

	// *** Handle Functions *** //
	// Handle change of page via navigation
	const handleMenuItemChange = () => {
		// Map through array and change active value
		var newMenuItemList = initialRouteList.map((item) => {
			if (item.RouteLink === location.pathname) {
				return { ...item, IsActive: true }
			} else {
				return { ...item, IsActive: false }
			}
		})
		// Set new list
		setMenuItemList(newMenuItemList)
	}

	// Handle adding to side menu depending on role
	const addMenuItem = (
		permissionRequired: number,
		RouteLabel: string
	): boolean => {
		// Return variable
		var addMenuItem = false

		// Check if the user has the relevant permission to view page, if 0 then universal page with no limitations
		if (
			permissionRequired === 0 ||
			loggedInUserPermissions.find(
				(perm) => Number(perm.PermissionID) === permissionRequired
			)
		) {
			addMenuItem = true
		}

		if (RouteLabel === 'Teams User Management') {
			if (!showTeamsUMItem.current) {
				addMenuItem = false
			}
		}

		return addMenuItem
	}

	// Handle Navigate
	const handleNavigate = (route: string) => {
		navigate(route)

		// Check if it is a smaller screen and if the menu is not collapsed then collapse
		if (isSmallerScreen && !isCollapsed) {
			toggleSideMenu()
		}
	}

	return isAuthenticated && user ? (
		<>
			<Box
				id='sidemenu'
				bgcolor='background.paper'
				className={
					isCollapsed ? 'sidemenu-container close' : 'sidemenu-container'
				}>
				<Box className='sidemenu-content'>
					{/* Content will be in a flex to constrain the avatar */}
					{/* Menu Icon and Logo*/}
					<Box className='sidemenu-header'>
						{/* Menu icon - Closes and opens the menu */}
						<IconButton
							id='menu'
							className='menu-icon'
							onClick={toggleSideMenu}
							sx={{
								border: `1px solid ${theme.palette.primary.main}`,
							}}>
							{isCollapsed ? (
								<MenuIcon sx={{ color: 'text.primary' }} />
							) : (
								<MenuOpenIcon sx={{ color: 'text.primary' }} />
							)}
						</IconButton>
						{/* Logo */}
						{logoURL.length > 0 ? (
							<img src={logoURL} alt='HALO Logo' />
						) : (
							<img
								src={require('../../../../assets/images/generic/logo/halo-logo.png')}
								alt='HALO Logo'
							/>
						)}
					</Box>
					{/* List of menu items */}
					<Box component='nav'>
						{/* Main Menu */}
						<MenuList className='menu-list' sx={{ padding: 0 }}>
							{/* Map list */}
							{menuItemList.map((item, index) => {
								return (
									item.MenuCategory === MenuCategories.Main &&
									addMenuItem(item.PermissionRequired, item.RouteLabel) && (
										<Tooltip
											key={`main_${index}`}
											title={item.RouteLabel}
											placement='right'>
											<MenuItem
												key={`main_${index}`}
												className='menu-item'
												sx={{
													bgcolor: item.IsActive
														? 'primary.main'
														: 'background.paper',
													':hover': {
														bgcolor: menuItemHover,
													},
												}}
												onClick={() => handleNavigate(item.RouteLink)}>
												<ListItemIcon
													sx={{
														color: item.IsActive
															? 'primary.contrastText'
															: 'text.primary',
													}}>
													{item.RouteIcon}
												</ListItemIcon>
												<ListItemText>
													<Typography
														component='p'
														sx={{
															color: item.IsActive
																? 'primary.contrastText'
																: 'text.primary',
														}}>
														{item.RouteLabel}
													</Typography>
												</ListItemText>
											</MenuItem>
										</Tooltip>
									)
								)
							})}
						</MenuList>
					</Box>
				</Box>
				{/* Secondary Menu */}
				<Box className='sidemenu-content extra'>
					{/* Avatar and extras menu */}
					<Box component='nav' className='sidemenu-extras'>
						{/* Extras Menu */}
						<MenuList className='menu-list' sx={{ padding: 0 }}>
							{/* Map list */}
							{menuItemList.map((item, index) => {
								return (
									item.MenuCategory === MenuCategories.Other &&
									addMenuItem(item.PermissionRequired, item.RouteLabel) && (
										<Tooltip
											key={`ext_${index}`}
											title={item.RouteLabel}
											placement='right'>
											<MenuItem
												key={`ext_${index}`}
												className='menu-item'
												sx={{
													bgcolor: item.IsActive
														? 'primary.main'
														: 'background.paper',
													':hover': {
														bgcolor: menuItemHover,
													},
												}}
												onClick={() => handleNavigate(item.RouteLink)}>
												<ListItemIcon
													sx={{
														color: item.IsActive
															? 'primary.contrastText'
															: 'text.primary',
													}}>
													{item.RouteIcon}
												</ListItemIcon>
												<ListItemText>
													<Typography
														component='p'
														sx={{
															color: item.IsActive
																? 'primary.contrastText'
																: 'text.primary',
														}}>
														{item.RouteLabel}
													</Typography>
												</ListItemText>
											</MenuItem>
										</Tooltip>
									)
								)
							})}
							{/* Logout */}
							<MenuItem
								className='menu-item extras'
								onClick={() =>
									logout({ logoutParams: { returnTo: window.location.origin } })
								}
								sx={{
									':hover': {
										bgcolor: menuItemHover,
									},
								}}>
								<ListItemIcon
									sx={{
										color: 'text.primary',
									}}>
									<LogoutOutlinedIcon />
								</ListItemIcon>
								<ListItemText
									sx={{
										color: 'text.primary',
									}}>
									<Typography component='p'>Logout</Typography>
								</ListItemText>
							</MenuItem>
						</MenuList>
					</Box>
				</Box>
				{/* Avatar */}
				<Box className='sidemenu-avatar'>
					<AvatarBox />
				</Box>
				{/* Copy right */}
				<Box component='footer'>
					<Typography component='p'>
						© {new Date().getFullYear()} HALO Portal. All Rights Reserved.
					</Typography>
				</Box>
			</Box>
		</>
	) : (
		// Show empty hexagon display
		<>
			<Box className='sidemenu-placeholder' />
		</>
	)
}
