import './CustomerDashboard.scss'
import { Box } from '@mui/material'
import { CustomerDashboardCards } from './CustomerDashboardCards/CustomerDashboardCards'
import { useEffect, useState } from 'react'
import { useCheckIfCustomerHasProductMutation } from '../../../../../services/proxyAPIData'
import { ErrorHandling } from '../../../../../utils/customHooks/ErrorHandling/ErrorHandling'
import {
	toAlphaString,
	toBetaString,
} from '../../../../../utils/helperFunctions/helperFunctions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'

export const CustomerDashboard = () => {
	const [checkIfCustomerHasProduct] = useCheckIfCustomerHasProductMutation()
	const addErrorLog = ErrorHandling()
	const SDAProductID = 'SDA'
	const [showDashboardCards, setShowDashboardCards] = useState(false)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	useEffect(() => {
		CheckIfCustomerCanDoSDA(loggedInUser.customerID)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loggedInUser])

	const CheckIfCustomerCanDoSDA = async (customerID: string) => {
		var canCustomerDoSDA = false
		// check if logged in customer can do SDA
		//If so, set flag to show Dashboard cards
		var encCustomerID = toBetaString(customerID)
		var encProductID = toBetaString(SDAProductID)
		var checkIfCustomerHasSDAProductError = ''

		var checkIfCustomerHasSDAProductResp = await checkIfCustomerHasProduct(
			`customerID=${encCustomerID}&productID=${encProductID}`
		)
			.unwrap()
			.catch((error) => {
				checkIfCustomerHasSDAProductError = JSON.stringify(error)
			})

		var decryptedCheckIfCustomerHasSDAProductResp = await toAlphaString(
			checkIfCustomerHasSDAProductResp?.Content + ''
		)

		try {
			canCustomerDoSDA = JSON.parse(
				decryptedCheckIfCustomerHasSDAProductResp
			) as boolean
		} catch (error) {
			canCustomerDoSDA = false
			await addErrorLog({
				ActionPerformed: 'Generic Portal Check if Customer can do SDA',
				ErrorInfo: `${JSON.stringify(
					error
				)} ${checkIfCustomerHasSDAProductError}`,
			})
		}

		setShowDashboardCards(canCustomerDoSDA)
	}

	return (
		<>
			{showDashboardCards && (
				<Box className='temp-container'>
					<CustomerDashboardCards />
				</Box>
			)}
		</>
	)
}
