import {
	DataGridProProps,
	GridColDef,
	GridFilterModel,
	GridFooter,
	GridFooterContainer,
	GridRenderCellParams,
	GridValidRowModel,
} from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useState } from 'react'
import {
	FilteredOrderStatus,
	OrderFilterKeyMappings,
	OrderOverviewModals,
	Permissions,
} from '../../../../../utils/enums/enums'
import {
	Box,
	Modal,
	Divider,
	Tooltip,
	Typography,
	styled,
	Alert,
	useTheme,
	Button,
	Badge,
	Popover,
	Backdrop,
	IconButton,
	MenuItem,
} from '@mui/material'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import { StyledDataGrid } from '../../../../../styles/styledComponents/displays/StyledDataGrid'
import {
	Customer,
	SKUPartnerMap,
} from '../../../../../utils/interfaces/DBModels'
import { PermissionChecker } from '../../../../../utils/customHooks/PermissionChecker/PermissionChecker'
import { StyledButton } from '../../../../../styles/styledComponents/inputs/StyledButton'
import AddOrder from './AddOrder/AddOrder'
import StyledModal from '../../../../../styles/styledComponents/displays/StyledModal'
import EmptyDisplay from '../../../displays/EmptyDisplay/EmptyDisplay'
import {
	FilteredOrderDisplay,
	FilteredOrderResponse,
	FilteredOrderSKUDisplay,
} from '../../../../../utils/interfaces/APIModels'
import SkipPreviousOutlinedIcon from '@mui/icons-material/SkipPreviousOutlined'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined'
import { useAppDispatch } from '../../../../../store/hooks'
import {
	setOrderFilter,
	setOrderPageNo,
	setOrderPageSize,
	resetOrderFilters,
} from '../../../../../store/reducers/reducers'
import OrderFilters from './OrderFilters/OrderFilters'
import { OrderFilter } from '../../../../../utils/interfaces/ComponentModels'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import SettingsIcon from '@mui/icons-material/Settings'
import './OrderFilters/OrderFilters.scss'
import AssignTNAddresses from './AssignTNAddresses/AssignTNAddresses'
import { StyledLoadingButton } from '../../../../../styles/styledComponents/inputs/StyledLoadingButton'

const customBoxStyle = {
	display: 'flex',
	flexDirection: 'row',
	columnGap: '5px',
	alignItems: 'center',
}

const OrdersOverviewDisplay = ({
	orderResponse,
	orderDisplay,
	pageNo,
	pageSize,
	callStatus,
	customerList,
	skuPartnerMapList,
	orderSKUList,
	standardNoRangeNoTermSKUs,
	getOrders,
	isCustomerOverview = false,
	isPartner,
}: {
	orderResponse: FilteredOrderResponse
	orderDisplay: FilteredOrderDisplay[]
	pageNo: number
	pageSize: number
	callStatus: number
	customerList: Customer[]
	skuPartnerMapList: SKUPartnerMap[]
	orderSKUList: FilteredOrderSKUDisplay[]
	standardNoRangeNoTermSKUs: SKUPartnerMap[]
	getOrders?: any
	isCustomerOverview?: boolean
	isPartner: boolean
}) => {
	// General
	const dispatch = useAppDispatch()
	const theme = useTheme()

	// Permission Checker
	const checkForPermission = PermissionChecker()

	// Flags
	const [hasRows, setHasRows] = useState<boolean | undefined>(undefined)
	const [openOrderFormModal, setOpenOrderFormModal] = useState(false)
	const [filterLoading, setFilterLoading] = useState(false)
	const [navLoadingType, setNavLoadingType] = useState('')

	// Display constants
	const [orderRows, setOrderRows] = useState([] as FilteredOrderDisplay[])
	const [currentPageNo, setCurrentPageNo] = useState(0)
	const [currentPageSize, setCurrentPageSize] = useState(0)
	const [totalOrderCount, setTotalOrderCount] = useState(0)
	const [totalOrderPages, setTotalOrderPages] = useState(0)
	const [orderFilters, setOrderFilters] = useState([] as OrderFilter[])
	const [filterAnchorEl, setFilterAnchorEl] =
		useState<HTMLButtonElement | null>(null)
	const openFilter = Boolean(filterAnchorEl)
	const filterID = openFilter ? 'simple-popover' : undefined
	const hideSipcomDirectColumn = true

	//Filters
	const [orderFilterQuery, setOrderFilterQuery] = useState(
		{} as Record<string, string>
	)

	// Filters - Quick filters for the datagrid
	const [filterModel, setFilterModel] = useState<GridFilterModel>({
		items: [],
	})

	//info styling
	const InfoAlert = styled(Alert)({
		'& .MuiAlert-icon': {
			color: 'black',
		},
		backgroundColor: theme.palette.secondary.main,
		color: 'black',
		fontSize: '14px',
	})

	// Column Definition: Order Table
	const initialOrderColumns: GridColDef[] = [
		{
			field: 'OrderID',
			headerName: 'Order ID',
			hideable: false,
			flex: 1,
		},
		{
			field: 'PartnerOrderID',
			headerName: 'Order Reference',
			hideable: false,
			flex: 1,
		},
		isCustomerOverview
			? (null as any)
			: {
					field: 'CustomerPartnerReference',
					headerName: 'Customer Reference',
					hideable: false,
					flex: 1,
			  },
		isCustomerOverview
			? (null as any)
			: {
					field: 'CustomerName',
					headerName: 'Customer Name',
					width: 220,
					hideable: false,
					flex: 1,
			  },
		{
			field: 'OrderStatus',
			headerName: 'Order Status',
			width: 220,
			hideable: false,
			flex: 1,
			// Note - Removed the display for the chip for now until we find a better style
		},
		{
			field: 'OrderType',
			headerName: 'Order Type',
			width: 220,
			hideable: false,
			flex: 1,
		},
		{
			field: 'PreviousOrderID',
			headerName: 'Previous Order ID',
			width: 220,
			hideable: false,
			flex: 1,
		},
		{
			field: 'ExternalOrderID',
			headerName: 'Generated Order ID',
			width: 220,
			hideable: false,
			flex: 1,
		},
		//dont show column for now
		hideSipcomDirectColumn
			? (null as any)
			: {
					field: 'SipcomDirect',
					headerName: 'Sipcom Direct',
					width: 220,
					hideable: false,
					flex: 1,
			  },
	].filter(Boolean)

	useEffect(() => {
		// Set rows
		if (orderResponse && orderDisplay) {
			setTotalOrderCount(Number(orderResponse.TotalCount))
			setTotalOrderPages(Number(orderResponse.TotalPages))
			setOrderRows(orderDisplay as FilteredOrderDisplay[])
			setHasRows(true)
		} else {
			setHasRows(false)
		}

		// Current page
		if (pageNo) {
			setCurrentPageNo(pageNo)
			dispatch(setOrderPageNo(pageNo))
		}
		if (pageSize) {
			setCurrentPageSize(pageSize)
			dispatch(setOrderPageSize(pageSize))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderResponse, orderSKUList, orderDisplay, initialOrderColumns])

	// Handle page navigation
	const handlePageNavigation = async (navType: string) => {
		setNavLoadingType(navType)
		// Page number
		var navPageNumber = currentPageNo
		var filtersToUse = orderFilterQuery

		// Check what was click
		switch (navType) {
			// Prev
			case 'prev':
				navPageNumber--
				break
			// Next
			case 'next':
				navPageNumber++
				break
			// Skip to first page1
			case 'first-page':
				navPageNumber = 1
				break
			// Skip to last page
			case 'last-page':
				navPageNumber = totalOrderPages
				break
			// Refresh
			case 'refresh':
				// Return to page 1 and clear filters
				navPageNumber = 1
				filtersToUse = {}
				setOrderFilterQuery(filtersToUse)
				setOrderFilters([])
				break
		}

		// Make call
		await getOrders({
			pageNo: navPageNumber,
			pageSize: currentPageSize,
			filters: filtersToUse,
		})

		// Dispatch
		dispatch(setOrderPageNo(navPageNumber))
		dispatch(setOrderFilter(filtersToUse))
		setNavLoadingType('')
	}

	//Custom components
	//1 - Custom Footer
	function CustomFooter() {
		return (
			<GridFooterContainer className='order-footer'>
				<GridFooter sx={{ borderTop: 'none' }} />
				<Box className='order-footer-content'>
					{/* Total rows */}
					<Typography component='p'>
						Page <strong>{currentPageNo}</strong> - {totalOrderPages} of{' '}
						{totalOrderCount} Results
					</Typography>
					{/* Pagination */}
					<Box className='order-pagination'>
						{/* Skip to first page */}
						<Tooltip title='Skip to first page'>
							<span>
								<StyledLoadingButton
									disabled={currentPageNo === 1}
									onClick={() => handlePageNavigation('first-page')}>
									<SkipPreviousOutlinedIcon />
								</StyledLoadingButton>
							</span>
						</Tooltip>
						{/* Previous */}
						<Tooltip title='Previous page'>
							<span>
								<StyledLoadingButton
									disabled={currentPageNo === 1}
									onClick={() => handlePageNavigation('prev')}>
									<NavigateBeforeIcon />
								</StyledLoadingButton>
							</span>
						</Tooltip>
						{/* Next */}
						<Tooltip title='Next Page'>
							<span>
								<StyledLoadingButton
									disabled={currentPageNo === totalOrderPages}
									onClick={() => handlePageNavigation('next')}>
									<NavigateNextIcon />
								</StyledLoadingButton>
							</span>
						</Tooltip>
						{/* Skip to lasy page */}
						<Tooltip title='Skip to last page'>
							<span>
								<StyledLoadingButton
									disabled={currentPageNo === totalOrderPages}
									onClick={() => handlePageNavigation('last-page')}>
									<SkipNextOutlinedIcon />
								</StyledLoadingButton>
							</span>
						</Tooltip>
					</Box>
				</Box>
			</GridFooterContainer>
		)
	}

	// Order Term
	const displayOrderTerm = (years: number, months: number): string => {
		const yearsString =
			years === 1 ? `${years} Year` : years > 1 ? `${years} Years` : ''
		const monthsString =
			months === 1 ? `${months} Month` : months > 1 ? `${months} Months` : ''

		if (yearsString === '' && monthsString === '') {
			return 'No Term'
		}

		// Convert to string and return
		return [yearsString, monthsString].filter(Boolean).join(' ')
	}

	// Add order info content panel - Used to display orderSKU table only for partner admin
	function OrderSKUContent({ row: rowProp }: { row: FilteredOrderDisplay }) {
		const [customerID, setCustomerID] = useState('')
		const [orderSKUID, setOrderSKUID] = useState(0)
		const [modalType, setModalType] = useState(0)
		const [orderSKUAnchorEl, setOrderSKUAnchorEl] =
			useState<null | HTMLElement>(null)
		const openOrderSKUMenu = Boolean(orderSKUAnchorEl)
		const [openOrderSKUModal, setOpenOrderSKUModal] = useState(false)

		// Handle open of order SKUs option menu
		const openOrderSKUOptionsMenu = (
			e: React.MouseEvent<HTMLButtonElement>
		) => {
			setOrderSKUAnchorEl(e.currentTarget)
		}

		// Handle close of order option menu
		const handleCloseOrderSKUMenu = () => {
			setOrderSKUAnchorEl(null)
		}

		// Open modal for DDIs
		const handleOpenOrderSKUModal = async (
			_customerID: string,
			_orderSKUID: number,
			modalType: number
		) => {
			// Dispatch to state
			setCustomerID(_customerID)
			setOrderSKUID(_orderSKUID)
			setModalType(modalType)
			setOpenOrderSKUModal(true)
		}

		// Close modal
		const handleCloseOrderSKUModal = () => {
			setModalType(0)
			setOpenOrderSKUModal(false)
		}

		const orderSKUColumns: GridColDef[] = [
			{
				field: 'SKUTypeID',
				headerName: 'SKU',
				hideable: false,
				flex: 1,
			},
			{
				field: 'Quantity',
				headerName: 'Quantity',
				hideable: false,
				flex: 1,
			},
			{
				field: 'OrderSKUTerm',
				headerName: 'SKU Term',
				flex: 1,
				renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
					<>
						<Typography component='p'>
							{displayOrderTerm(
								Number(params.row.SKUYears),
								Number(params.row.SKUMonths)
							)}
						</Typography>
					</>
				),
				// Render actions: Allow edit of SKU Term only if order price SKU price is required
			},
			{
				field: 'OrderSKUStatus',
				headerName: 'SKU Status',
				hideable: false,
				flex: 1,
			},
			{
				field: 'ActivationDate',
				renderHeader: () => (
					<>
						<Tooltip title='Activation Date of the first SKU of each Order is deemed to be the Commencement Date'>
							<InfoAlert severity='info' style={{ padding: '0' }}>
								{'Activation Date'}
							</InfoAlert>
						</Tooltip>
					</>
				),
				hideable: false,
				flex: 1,
				renderCell: (params: GridRenderCellParams<any>) => (
					<>
						<Box sx={customBoxStyle}>
							{params.value ? (
								<>
									<Typography component='p'>
										{new Date(params.value).toLocaleDateString()}{' '}
									</Typography>
								</>
							) : (
								''
							)}
						</Box>
					</>
				),
			},
			{
				field: 'CurrentProduct',
				headerName: 'Current Product',
				hideable: false,
				flex: 1,
			},
			{
				field: 'CurrencyName',
				headerName: 'Currency',
				hideable: false,
				flex: 1,
			},
			//only show for partner admin
			...(isPartner
				? [
						{
							field: 'Price',
							headerName: 'MRC (per unit)',
							flex: 1,
						},
						{
							field: 'NRC',
							headerName: 'NRC (per unit)',
							flex: 1,
						},
				  ]
				: ([] as any)),
			// Note - Removed edit logic
		]
		return (
			<Box display='flex' flexDirection='column' rowGap='20px' padding={3}>
				<Typography component='h2'>List of Order SKUs</Typography>
				<StyledDataGrid
					columns={[
						...orderSKUColumns,
						{
							field: 'actions',
							headerName: 'actions',
							flex: 1,
							renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
								<>
									{rowProp.SipcomDirect && params.row.DDIProductExists ? (
										<>
											<Tooltip title='Order SKU Options'>
												<IconButton
													id='order-sku-options-button'
													onClick={(e) => openOrderSKUOptionsMenu(e)}>
													<SettingsIcon />
												</IconButton>
											</Tooltip>
											<Popover
												className='order-options-menu'
												id='order-sku-options-menu'
												anchorEl={orderSKUAnchorEl}
												open={openOrderSKUMenu}
												onClose={handleCloseOrderSKUMenu}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'center',
												}}
												transformOrigin={{
													vertical: 'top',
													horizontal: 'center',
												}}>
												<MenuItem
													onClick={() => {
														handleOpenOrderSKUModal(
															rowProp.CustomerID + '',
															Number(params.row.OrderSKUID),
															OrderOverviewModals.AssignTNAddresses
														)
													}}>
													Assign TN Addresses
												</MenuItem>
											</Popover>
										</>
									) : (
										<Tooltip title='TN Addresses cannot be assigned to this SKU.'>
											<DoNotDisturbIcon />
										</Tooltip>
									)}
								</>
							),
						},
					]}
					rows={rowProp.OrderSKUs as FilteredOrderSKUDisplay[]}
					getRowId={(row) => row.OrderSKUID}
					autoPageSize={true}
					autoHeight={true}
					hideFooter
				/>
				{/* Modal */}
				<Modal open={openOrderSKUModal} onClose={handleCloseOrderFormModal}>
					<StyledModal width={1000}>
						{modalType === OrderOverviewModals.AssignTNAddresses && (
							<AssignTNAddresses
								customerID={customerID}
								orderSKUID={orderSKUID}
								handleCloseMenuAndModal={handleCloseOrderSKUModal}
							/>
						)}
					</StyledModal>
				</Modal>
			</Box>
		)
	}

	// Detail panel of main datagrid - Order SKU Display
	const getDetailPanelContent = useCallback<
		NonNullable<DataGridProProps['getDetailPanelContent']>
		// eslint-disable-next-line react-hooks/exhaustive-deps
	>(({ row }) => <OrderSKUContent row={row} />, [])

	// Set auto height to detail panel
	const getDetailPanelHeight = useCallback(() => 'auto', [])

	// *** Handle Functions *** //

	const handleOpenOrderFormModal = () => {
		setOpenOrderFormModal(true)
	}

	const handleCloseOrderFormModal = () => {
		setOpenOrderFormModal(false)
		//get latest orders
		getOrders()
	}

	// Get key by value for the key passed in
	const getKeyByValue = (value: string): string => {
		var returnKey = ''
		// Get mapping
		const keys = Object.keys(OrderFilterKeyMappings) as Array<
			keyof typeof OrderFilterKeyMappings
		>
		var findKey = keys.find((key) => OrderFilterKeyMappings[key] === value)
		if (findKey) {
			returnKey = findKey
		} else {
			returnKey = ''
		}
		return returnKey
	}

	// Build filter
	const createFiltersRecord = (
		filtersArray: OrderFilter[]
	): Record<string, string> => {
		return filtersArray.reduce((acc, filter) => {
			acc[getKeyByValue(filter.Key)] = filter.Value
			return acc
		}, {} as Record<string, string>)
	}

	// Handle open filter menu
	const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
		setFilterAnchorEl(event.currentTarget)
	}

	// Handle close filter menu
	const handleCloseFilter = () => {
		setFilterAnchorEl(null)
	}

	// Handle filter call logic
	const handleFilterCall = async (orderFilters: OrderFilter[]) => {
		// Loading
		setFilterLoading(true)

		// Set filters in case
		setOrderFilters(orderFilters)

		// Loop through filters and build filter dictionary
		var builtFilters = createFiltersRecord(orderFilters)
		if (builtFilters) {
			// Set and make call
			setOrderFilterQuery(builtFilters)

			//check if empty and reset global filer variable
			if (Object.keys(builtFilters).length === 0) {
				dispatch(resetOrderFilters())
			} else {
				dispatch(setOrderFilter(builtFilters))
			}

			await getOrders({
				pageNo: 1,
				pageSize: currentPageSize,
				filters: builtFilters,
			})
		}

		// Loading
		setFilterLoading(false)
	}

	return (
		<Box className='order-overview-container'>
			{isCustomerOverview === false && (
				<>
					<Box className='description'>
						<Typography component='p'>
							Manage the orders, view SKUs and customer info
						</Typography>
					</Box>
					<Box className='order-overview-filters'>
						{/* Filter button */}
						<Box>
							{/* Button */}
							<Button
								startIcon={
									<Badge badgeContent={orderFilters.length} color='primary'>
										<FilterListOutlinedIcon />
									</Badge>
								}
								variant='outlined'
								aria-describedby={filterID}
								onClick={handleOpenFilter}>
								Filter
							</Button>
							{/* Filter popover */}
							<Popover
								id={filterID}
								open={openFilter}
								anchorEl={filterAnchorEl}
								onClose={handleCloseFilter}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}>
								<OrderFilters
									orderFilters={orderFilters}
									handleFilterCall={handleFilterCall}
								/>
							</Popover>
						</Box>

						<Box>
							{checkForPermission(Permissions.AddOrders) && (
								<StyledButton
									startIcon={
										<img
											src={require('../../../../../assets/images/generic/icons/plus-icon.png')}
											alt='plus icon'
											style={{ width: '10px', height: '10px' }}
										/>
									}
									onClick={handleOpenOrderFormModal}>
									Add new order
								</StyledButton>
							)}
						</Box>
					</Box>

					<Divider />
				</>
			)}

			{/* Datagrid: Orders (Parent) and Order SKUs (Child) */}
			{callStatus === FilteredOrderStatus.Success && (
				<StyledDataGrid
					rows={orderRows}
					columns={initialOrderColumns}
					editMode='row'
					checkboxSelection={false}
					pageSizeOptions={[5]}
					slots={{
						footer: CustomFooter,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					getRowId={(row) => row.OrderID}
					autoHeight={true}
					rowSelection={false}
					getDetailPanelHeight={getDetailPanelHeight}
					getDetailPanelContent={getDetailPanelContent}
					filterModel={filterModel}
					onFilterModelChange={(newFilterModel) =>
						setFilterModel(newFilterModel)
					}
					hideFooterRowCount
					disableColumnMenu
				/>
			)}
			{/* open modal */}
			<Modal open={openOrderFormModal} onClose={handleCloseOrderFormModal}>
				<StyledModal width={700}>
					<Box className='order-form-content'>
						<AddOrder
							customerList={customerList}
							skuPartnerMapList={skuPartnerMapList}
							standardNoRangeNoTermSKUs={standardNoRangeNoTermSKUs}
							handleCloseOrderFormModal={handleCloseOrderFormModal}
						/>
					</Box>
				</StyledModal>
			</Modal>
			{hasRows === false ? (
				<EmptyDisplay
					title='No orders found'
					description='There were no orders found'
				/>
			) : null}
			{/* Loading Backdrop */}
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={navLoadingType.length > 0 || filterLoading}
			/>
		</Box>
	)
}

export default OrdersOverviewDisplay
