import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import {
	Roles,
	SDAStatus,
	SMInstallOnlyLocation,
	ServiceManagementStatusCheck,
	TeamsDRServiceValueDisplayOptions,
} from '../../../../../../utils/enums/enums'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import {
	MSTeamsUser,
	Service,
	ServiceManagementReport,
	ServiceManagementStatus,
} from '../../../../../../utils/interfaces/DBModels'
import {
	GetEmptyAccountInfo,
	getUTCDateTimeNow,
	showErrorToast,
	showSuccessToast,
	timeout,
	toAlphaString,
	toBetaString,
} from '../../../../../../utils/helperFunctions/helperFunctions'
import AnimatedBox from '../../../../../shared/displays/AnimatedBox/AnimatedBox'
import SimpleCard from '../../../../../shared/displays/AnimatedBox/SimpleCardContent/SimpleCardContent'
import LearnMore from '../../../LearnMore/LearnMore'
import { CustomerDashboardPrechecks } from '../../../../../../utils/customHooks/APICalls/CustomerDashboardPrechecks/CustomerDashboardPrechecks'
import {
	useCheckActiveTeamsDROrderSKUsForCustomerMutation,
	useGetServiceManagementReportAndStatusForCustomerMutation,
	useGetTenantProvisioningConfigMutation,
	usePostBetaObjectAndReturnDataMutation,
	useGetSDADDIStatusMutation,
} from '../../../../../../services/proxyAPIData'
import { SMNumberDisplayBox } from '../../../../../shared/displays/SMNumberDisplayBox/SMNumberDisplayBox'
import GroupsIcon from '@mui/icons-material/Groups'
import TeamsServiceSelection from '../../TeamsServiceSelection/TeamsServiceSelection'
import {
	BetaObject,
	TeamsUserManagementPrecheckResponse,
	TenantConfigurationInfo,
} from '../../../../../../utils/interfaces/APIModels'
import {
	DashboardLicenseOverview,
	SDAOrderSKUInfo,
} from '../../../../../../utils/interfaces/ComponentModels'
import { LicensesDisplayBox } from '../../../../../shared/displays/LicensesDisplayBox/LicensesDisplayBox'
import { PublicClientApplication } from '@azure/msal-browser'
import { GraphScopes } from '../../../../../../utils/constants/constants'
import { useAppDispatch } from '../../../../../../store/hooks'
import { setConfirmedAccount } from '../../../../../../store/reducers/reducers'
import { ErrorHandling } from '../../../../../../utils/customHooks/ErrorHandling/ErrorHandling'
import UseCrud from '../../../../../../utils/customHooks/APICalls/UseCrud'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import CustomLoaderWithIcon from '../../../../../shared/loading/customLoaderWithIcon/CustomLoaderWithIcon'
import SDAProvisioning from '../SDAProvisioning/SDAProvisioning'
import VerifiedIcon from '@mui/icons-material/Verified'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

export const CustomerDashboardCards = () => {
	// Global variables
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)
	const { instance } = useMsal()
	const isAuthenticated = useIsAuthenticated()

	// Hooks
	const {
		getTeamsServicesAndCustomerServices,
		getSDAOrderSKU,
		getServiceManagementReport,
		getServiceManagementStatus,
		getUserLicenses,
	} = CustomerDashboardPrechecks(loggedInUser.customerID)

	const [checkForActiveTeamsDROrderSKUs] =
		useCheckActiveTeamsDROrderSKUsForCustomerMutation()

	const [getTenantConfigInfo] = useGetTenantProvisioningConfigMutation()

	const [getSDADDIStatus] = useGetSDADDIStatusMutation()

	const addErrorLog = ErrorHandling()

	const { modifyData } = UseCrud()

	const [postObjectAndReturnData] = usePostBetaObjectAndReturnDataMutation()

	const [runSMChecksForCustomer] =
		useGetServiceManagementReportAndStatusForCustomerMutation()

	// Flags
	const [initialCallsMade, setInitialCallMade] = useState(false)
	const [smCardLoading, setSMCardLoading] = useState(true)
	const [licensesLoading, setLicensesLoading] = useState(true)
	const [teamsDRCardDescription, setTeamsDRCardDescription] = useState('')
	const [requiresSMInstall, setRequiresSMInstall] = useState(false)

	// Objects
	const [selectedService, setSelectedService] = useState('')
	const [selectedTeamsServiceID, setSelectedTeamsServiceID] = useState('')
	const [customerTeamsServices, setCustomerTeamsServices] = useState(
		[] as MSTeamsUser[]
	)
	const [serviceList, setServiceList] = useState([] as Service[])
	const [msServiceToUse, setMSServiceToUse] = useState({} as MSTeamsUser)

	const [
		teamsDRServiceValueDisplayOption,
		setTeamsDRServiceValueDisplayOption,
	] = useState(TeamsDRServiceValueDisplayOptions.PartnerServiceID)
	const [sdaLoadingTitle, setSDALoadingTitle] = useState('')
	const [sdaLoadingDescription, setSDALoadingDescription] = useState('')
	const [isReconfiguringTeamsDR, setIsReconfiguringTeamsDR] = useState(false)

	const [tenantProvisioningConfig, setTenantProvisioningConfig] = useState(
		{} as TenantConfigurationInfo
	)

	const [licenseOverview, setLicenseOverview] = useState({
		TotalLicenses: 0,
		AvailableLicenses: 0,
		TotalTeamsPhoneStandardLicenses: 0,
		AvailableTeamsPhoneStandardLicenses: 0,
	} as DashboardLicenseOverview)

	const [sdaOrderSKUInfo, setSDAOrderSKUInfo] = useState({} as SDAOrderSKUInfo)

	const [readyForSDA, setReadyForSDA] = useState(false)
	const [sdaCompleted, setSDACompleted] = useState(false)
	const [readyForSM, setReadyForSM] = useState(false)
	const [sdaIssue, setSDAIssue] = useState(false)
	const [smFailure, setSMFailure] = useState(false)
	const [isDoingSDA, setIsDoingSDA] = useState(false)
	const [SMToken, setSMToken] = useState('')

	const [sdaLoadingPercentage, setSDALoadingPercentage] = useState(10)
	const [smLoadingPercentage, setSMLoadingPercentage] = useState(0)

	//SM Teams DR Counts
	const [smTeamsDRUsersCount, setSMTeamsDRUsersCount] = useState(0)
	const [smCallQueuesCount, setSMCallQueuesCount] = useState(0)
	const [smAutoAttendantsCount, setSMAutoAttendantsCount] = useState(0)
	const [smTotalEndpoints, setSMTotalEndpoints] = useState(0)

	// General
	const dispatch = useAppDispatch()

	const theme = useTheme()

	useEffect(() => {
		// Check which role to make the relevant call
		switch (roleID) {
			// Customer Admin
			case Roles.CustomerAdmin:
				if (!initialCallsMade) {
					setInitialCallMade(true)
					getTeamsServicesForCustomers()
				}
				break
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roleID, initialCallsMade])

	const getTeamsServicesForCustomers = async () => {
		setSDALoadingPercentage(0)
		setCustomerTeamsServices([] as MSTeamsUser[])
		setSDALoadingTitle('Validating Teams Service/s')
		setSDALoadingDescription(
			'Checking if you have a Teams service configuration'
		)
		var customerHasValidTeamsDROrderSKUError = ''

		var customerHasValidTeamsDROrderSKUResp =
			await checkForActiveTeamsDROrderSKUs(
				toBetaString(loggedInUser.customerID + '')
			)
				.unwrap()
				.catch((error) => {
					customerHasValidTeamsDROrderSKUError = JSON.stringify(error)
				})

		var decryptedcustomerHasValidTeamsDROrderSKUResp = await toAlphaString(
			customerHasValidTeamsDROrderSKUResp?.Content + ''
		)

		var customerHasValidTeamsDROrderSKU = false

		try {
			customerHasValidTeamsDROrderSKU = JSON.parse(
				decryptedcustomerHasValidTeamsDROrderSKUResp
			) as boolean
		} catch (error) {
			await addErrorLog({
				ActionPerformed: 'Generic Portal Check for Active Teams DR Order SKUs',
				ErrorInfo: `Customer ${loggedInUser.customerID} ${JSON.stringify(
					error
				)} ${customerHasValidTeamsDROrderSKUError}`,
			})
		}

		if (customerHasValidTeamsDROrderSKU) {
			var teamsAndCustomerServices = await getTeamsServicesAndCustomerServices()
			if (teamsAndCustomerServices) {
				if (
					teamsAndCustomerServices?.TeamsServices &&
					teamsAndCustomerServices.TeamsServices.length > 0
				) {
					setCustomerTeamsServices(teamsAndCustomerServices.TeamsServices)

					if (teamsAndCustomerServices?.TeamsServices.length === 1) {
						setSDALoadingPercentage(33)
						setSelectedTeamsServiceID(
							teamsAndCustomerServices.TeamsServices[0]?.ServiceID + ''
						)

						var _selectedService =
							teamsAndCustomerServices.TeamsServices[0]?.ServiceID + ''

						if (
							(teamsAndCustomerServices.TeamsServices[0]?.DomainName + '')
								.length > 0
						) {
							_selectedService =
								teamsAndCustomerServices.TeamsServices[0]?.DomainName + ''
						}

						if (
							teamsAndCustomerServices?.Services &&
							teamsAndCustomerServices?.Services[0]?.PartnerServiceReference
						) {
							if (
								(
									teamsAndCustomerServices?.Services[0]
										?.PartnerServiceReference + ''
								).length > 0
							) {
								_selectedService =
									teamsAndCustomerServices?.Services[0]
										?.PartnerServiceReference + ''
							}
						}

						setSelectedService(_selectedService)

						await doMSTeamsDRPrechecks(
							teamsAndCustomerServices.TeamsServices[0]
						)
					} else {
						// Determine which value to show the customer to identify their different Teams services.
						// PartnerServiceID is the first preference, then Domain and lastly, the teams service ID.
						var _usePartnerServiceID = true
						var _useDomain = true

						if (
							teamsAndCustomerServices.Services &&
							teamsAndCustomerServices.Services.length > 0
						) {
							var customerServicesList = teamsAndCustomerServices.Services
							setServiceList(customerServicesList)

							teamsAndCustomerServices.TeamsServices.forEach((x) => {
								var matchingService = customerServicesList.find(
									(y) =>
										y.ServiceID?.toLocaleLowerCase().trim() ===
										x.ServiceID?.toLocaleLowerCase().trim()
								)

								if (
									!matchingService?.PartnerServiceReference ||
									(matchingService?.PartnerServiceReference + '').trim()
										.length === 0
								) {
									_usePartnerServiceID = false
								}

								if (
									!x?.DomainName ||
									(x?.DomainName + '').trim().length === 0
								) {
									_useDomain = false
								}
							})
						}

						setTeamsDRServiceValueDisplayOption(
							TeamsDRServiceValueDisplayOptions.PartnerServiceID
						)

						if (!_usePartnerServiceID) {
							if (_useDomain) {
								setTeamsDRServiceValueDisplayOption(
									TeamsDRServiceValueDisplayOptions.DomainName
								)
							} else {
								setTeamsDRServiceValueDisplayOption(
									TeamsDRServiceValueDisplayOptions.ServiceID
								)
							}
						}
					}

					await getSMValues(teamsAndCustomerServices.TeamsServices)
				} else {
					setSDAIssue(true)
					setTeamsDRCardDescription(
						'You do not have a configured MS Teams service yet.'
					)
				}
			}
		} else {
			setSMCardLoading(false)
			setSDAIssue(true)
			setTeamsDRCardDescription('No valid Teams DR order was found.')
		}
	}

	const getSMValues = async (msTeamsServicesList: MSTeamsUser[]) => {
		if (msTeamsServicesList && msTeamsServicesList.length > 0) {
			var totalUsers = 0
			var totalCallQueues = 0
			var totalAutoAttendants = 0
			var totalEndpoints = 0
			var customerHasSMReport = false

			for (
				var teamsServiceIndex = 0;
				teamsServiceIndex < msTeamsServicesList.length;
				teamsServiceIndex++
			) {
				var SMReportAndStatusForService = await getServiceManagementReport(
					msTeamsServicesList[teamsServiceIndex]?.ServiceID + ''
				)

				if (SMReportAndStatusForService?.ServiceID) {
					customerHasSMReport = true
				}

				if (
					SMReportAndStatusForService?.SipcomPlatformUserCount &&
					Number(SMReportAndStatusForService?.SipcomPlatformUserCount) > 0
				) {
					totalUsers += Number(
						SMReportAndStatusForService?.SipcomPlatformUserCount
					)
				}

				if (
					SMReportAndStatusForService?.TenantCallQueueCount &&
					Number(SMReportAndStatusForService?.TenantCallQueueCount) > 0
				) {
					totalCallQueues += Number(
						SMReportAndStatusForService?.TenantCallQueueCount
					)
				}

				if (
					SMReportAndStatusForService?.HALODRAutoAttendants &&
					Number(SMReportAndStatusForService?.HALODRAutoAttendants) > 0
				) {
					totalAutoAttendants += Number(
						SMReportAndStatusForService?.HALODRAutoAttendants
					)
				}
			}
			totalEndpoints = totalUsers + totalCallQueues + totalAutoAttendants

			if (totalEndpoints > 0) {
				setSMTotalEndpoints(totalEndpoints)
				setSMTeamsDRUsersCount(totalUsers)
				setSMCallQueuesCount(totalCallQueues)
				setSMAutoAttendantsCount(totalAutoAttendants)
			}

			if (!customerHasSMReport) {
				setRequiresSMInstall(true)
			}

			setSMCardLoading(false)
		}
	}

	const doMSTeamsDRPrechecks = async (msTeamsServiceToUse: MSTeamsUser) => {
		setMSServiceToUse(msTeamsServiceToUse)

		// Make all prechecks call and build cards
		// Variables to build
		var singleCardDescription = ''
		var _tenantConfigInfo = {} as TenantConfigurationInfo
		var _validTeamsDDICheck = {} as TeamsUserManagementPrecheckResponse

		// Flags
		var sdaProcessReadyOrCompleted = false
		var sdaProcessCompleted = false
		var hasValidDDIs = false
		var successSMStatus = false
		var hasValidTenantConfig = true
		var hasValidTeamsDDIs = true

		await getTenantConfigInfo(toBetaString(loggedInUser.customerID + ''))
			.unwrap()
			.then(async (tenantConfigDataResponse) => {
				var decryptedTenantConfigResponse = await toAlphaString(
					tenantConfigDataResponse?.Content + ''
				)

				_tenantConfigInfo = JSON.parse(
					decryptedTenantConfigResponse
				) as TenantConfigurationInfo

				if (
					_tenantConfigInfo.Domains &&
					_tenantConfigInfo.Regions &&
					_tenantConfigInfo.Domains?.length > 0 &&
					_tenantConfigInfo.Regions?.length > 0
				) {
					var regionsForSelectedService = _tenantConfigInfo.Regions.filter(
						(x) =>
							(x?.ServiceID + '').toLowerCase().trim() ===
							(msTeamsServiceToUse?.ServiceID + '').toLowerCase().trim()
					)
					var domainsForSelectedService = _tenantConfigInfo.Domains.filter(
						(x) =>
							(x?.ServiceID + '').toLowerCase().trim() ===
							(msTeamsServiceToUse?.ServiceID + '').toLowerCase().trim()
					)

					if (
						regionsForSelectedService.length === 0 ||
						domainsForSelectedService.length === 0
					) {
						hasValidTenantConfig = false
					} else {
						setTenantProvisioningConfig(_tenantConfigInfo)
					}
				} else {
					hasValidTenantConfig = false
				}
			})
			.catch(() => {
				hasValidTenantConfig = false
			})

		var teamsDROrderSKUInfo = await getSDAOrderSKU(
			msTeamsServiceToUse?.ServiceID + ''
		)

		setSDAOrderSKUInfo(teamsDROrderSKUInfo)

		if (teamsDROrderSKUInfo.SDAStatus >= 0 && hasValidTenantConfig) {
			// Check status
			switch (teamsDROrderSKUInfo.SDAStatus) {
				// No Teams Order Found
				case SDAStatus.NoTeamsOrderSKUFound:
					singleCardDescription =
						'You do not have a configured Teams Order yet.'
					setSDAIssue(true)
					setLicensesLoading(false)
					setLicenseOverview({
						TotalLicenses: 0,
						AvailableLicenses: 0,
						TotalTeamsPhoneStandardLicenses: 0,
						AvailableTeamsPhoneStandardLicenses: 0,
					} as DashboardLicenseOverview)
					break
				// Not ready for SDA
				case SDAStatus.NotReadyForSDA:
					singleCardDescription =
						'Your Teams order is not ready for the Teams Direct Routing process yet. Please try again later.'
					setSDAIssue(true)
					setLicensesLoading(false)
					setLicenseOverview({
						TotalLicenses: 0,
						AvailableLicenses: 0,
						TotalTeamsPhoneStandardLicenses: 0,
						AvailableTeamsPhoneStandardLicenses: 0,
					} as DashboardLicenseOverview)
					break
				// Ready for SDA
				case SDAStatus.ReadyForSDA:
					sdaProcessReadyOrCompleted = true
					setReadyForSDA(true)
					singleCardDescription =
						'Configure Service Manager and complete the Teams Direct Routing process'
					break
				// SDA completed
				case SDAStatus.SDACompleted:
					sdaProcessReadyOrCompleted = true
					sdaProcessCompleted = true
					break
			}

			// If SDA is done, then make DDIs and Trunk call
			if (sdaProcessReadyOrCompleted) {
				// Progress and flag
				setSDALoadingPercentage(66)

				// Call 3: DDIs and Trunks
				setSDALoadingTitle('Validating DDIs and Trunks')
				setSDALoadingDescription(
					'Checking if you have DDIs and Trunks and both belong to the same regions'
				)

				var validTeamsDDIsCheck = await getSDADDIStatus(
					toBetaString(msTeamsServiceToUse?.ServiceID + '')
				)
					.unwrap()
					.catch(() => {
						hasValidTeamsDDIs = false
					})

				if (hasValidTeamsDDIs) {
					var decryptedValidTeamsDDIsResponse = await toAlphaString(
						validTeamsDDIsCheck?.Content + ''
					)

					_validTeamsDDICheck = JSON.parse(
						decryptedValidTeamsDDIsResponse
					) as TeamsUserManagementPrecheckResponse
				} else {
					singleCardDescription = 'Unable to Validate MS Teams DDIs'
					setSDAIssue(true)
					setSDALoadingPercentage(100)
				}

				if (
					hasValidTeamsDDIs &&
					_validTeamsDDICheck &&
					_validTeamsDDICheck.customerID
				) {
					if (_validTeamsDDICheck.allPrechecksPassed) {
						hasValidDDIs = true
						setSDACompleted(sdaProcessCompleted)
					} else {
						singleCardDescription = _validTeamsDDICheck?.message + ''
						setSDAIssue(true)
					}

					if (hasValidDDIs) {
						// Progress and flag
						setSDALoadingPercentage(100)

						if (sdaProcessCompleted) {
							// Call 4: Service Manager Status and Report
							var serviceManagementCheck = await getServiceManagementStatus(
								msTeamsServiceToUse?.ServiceID + ''
							)

							if (Number(serviceManagementCheck) >= 0) {
								// Check status
								switch (Number(serviceManagementCheck)) {
									// No status
									case Number(
										ServiceManagementStatusCheck.NoServiceManagementConfigFound
									):
										singleCardDescription =
											'Configure Service Manager (recommended).'
										setReadyForSM(true)
										break

									// Failed status
									case ServiceManagementStatusCheck.ServiceManagementFailure:
										singleCardDescription =
											'An error was found. Please reconfigure Teams Direct Routing.'
										setSMFailure(true)
										break

									// Success status
									case ServiceManagementStatusCheck.ServiceManagementSuccess:
										successSMStatus = true
										break
								}

								// If there is a success service manager, then only make calls to get license details
								if (successSMStatus) {
									await getLicenseDetails(
										msTeamsServiceToUse,
										_tenantConfigInfo
									)
								}
							}
						}
					}
				}
				setLicensesLoading(false)
				setSDALoadingPercentage(100)
			} else {
				setSDALoadingPercentage(100)
			}
		} else {
			singleCardDescription = 'Your Teams Service information is invalid.'
			setSDAIssue(true)
			setLicensesLoading(false)
			setLicenseOverview({
				TotalLicenses: 0,
				AvailableLicenses: 0,
				TotalTeamsPhoneStandardLicenses: 0,
				AvailableTeamsPhoneStandardLicenses: 0,
			} as DashboardLicenseOverview)
			setSDALoadingPercentage(100)
		}

		setTeamsDRCardDescription(singleCardDescription)
	}

	const getLicenseDetails = async (
		msTeamsServiceToUse: MSTeamsUser,
		_tenantConfigInfo: TenantConfigurationInfo
	) => {
		setSMLoadingPercentage(100)

		var licenseOverview = await getUserLicenses(msTeamsServiceToUse)

		if (licenseOverview) {
			var dashboardLicenceOverview: DashboardLicenseOverview = {
				AvailableLicenses: licenseOverview.TotalAvailableLicenses,
				AvailableTeamsPhoneStandardLicenses:
					licenseOverview.TeamsPhoneStandardLicencesAvailable,
				TotalLicenses: licenseOverview.TotalPurchasedLicenses,
				TotalTeamsPhoneStandardLicenses:
					licenseOverview.TeamsPhoneStandardLicencesTotal,
			}

			setLicenseOverview(dashboardLicenceOverview)
		}
	}

	const confirmMSTeamsService = async (msTeamsServiceID: string) => {
		var msServiceToUse = customerTeamsServices.find(
			(x) => x.ServiceID === msTeamsServiceID
		)

		if (msServiceToUse) {
			setSelectedTeamsServiceID(msServiceToUse?.ServiceID + '')

			switch (teamsDRServiceValueDisplayOption) {
				case TeamsDRServiceValueDisplayOptions.PartnerServiceID:
					var matchingService = serviceList.find(
						(x) => x?.ServiceID + '' === msServiceToUse?.ServiceID + ''
					)
					if (matchingService) {
						setSelectedService(matchingService?.PartnerServiceReference + '')
					}
					break
				case TeamsDRServiceValueDisplayOptions.DomainName:
					setSelectedService(msServiceToUse?.DomainName + '')
					break
				default:
					setSelectedService(msServiceToUse?.ServiceID + '')
					break
			}

			setSDALoadingPercentage(33)
			await doMSTeamsDRPrechecks(msServiceToUse)
		}
	}

	const resetTeamsServiceSelection = (keepSelectedService?: boolean) => {
		if (!keepSelectedService) {
			setSelectedService('')
			setSelectedTeamsServiceID('')
		}
		setSDALoadingTitle('Validating Teams Service')
		setSDALoadingDescription(
			'Checking if you have a Teams service configuration'
		)
		setSDALoadingPercentage(10)
		setReadyForSDA(false)
		setSDACompleted(false)
		setReadyForSM(false)
		setSDAIssue(false)
		setSMFailure(false)
		setTeamsDRCardDescription('')

		setLicensesLoading(true)
		setLicenseOverview({
			TotalLicenses: 0,
			AvailableLicenses: 0,
			TotalTeamsPhoneStandardLicenses: 0,
			AvailableTeamsPhoneStandardLicenses: 0,
		} as DashboardLicenseOverview)
	}

	const reloadDashboardAfterSDA = async (isProvisioningSuccess: boolean) => {
		if (isProvisioningSuccess) {
			showSuccessToast(
				'Provisioning Done, Service Manager results can take up to 8 hours to reflect'
			)

			if (isReconfiguringTeamsDR) {
				await resetSMStatus()
			}

			setRequiresSMInstall(false)
		}

		setIsDoingSDA(false)
		resetTeamsServiceSelection(true)
		await doMSTeamsDRPrechecks(msServiceToUse)
	}

	const installSMOnly = async (smInstallLocation: SMInstallOnlyLocation) => {
		setSMCardLoading(true)
		var smErrorMessage = 'Consent to Service Manager App was unsuccessful.'

		var msalSMInstance = new PublicClientApplication({
			auth: {
				clientId: process.env.REACT_APP_SM_SETUP_CLIENT_ID + '',
			},
			system: {
				allowRedirectInIframe: true,
			},
		})

		await msalSMInstance.initialize()

		const SMApplogInRequest = {
			scopes: ['Organization.Read.All'],
			prompt: 'select_account',
		}

		await msalSMInstance
			.loginPopup(SMApplogInRequest)
			.then(async (authenticationResult) => {
				var accInfo = authenticationResult.account

				if (accInfo) {
					await msalSMInstance
						.logoutRedirect({
							onRedirectNavigate: () => {
								// Return false to stop navigation after local logout
								return false
							},
						})
						.then(async () => {
							var smInstallErrorMessage =
								'Service Manager App install was unsuccessful.'
							var isInstallSuccessful = true

							var msalSMInstallInstance = new PublicClientApplication({
								auth: {
									clientId:
										process.env.REACT_APP_SM_INSTALL_ONLY_CLIENT_ID + '',
								},
								system: {
									allowRedirectInIframe: true,
								},
							})

							await msalSMInstallInstance.initialize()

							const SMAppInstallRequest = {
								scopes: GraphScopes,
								prompt: 'select_account',
							}

							await msalSMInstallInstance
								.loginPopup(SMAppInstallRequest)
								.then(async (authenticationResult) => {
									var accInfo = authenticationResult.account
									if (accInfo) {
										showSuccessToast(
											'Please wait while we verify app installation'
										)
										await timeout(20000)
										var _SMtoken =
											await msalSMInstallInstance.acquireTokenSilent({
												scopes: GraphScopes,
												account: accInfo,
											})

										if ((_SMtoken?.accessToken + '').length < 1) {
											isInstallSuccessful = false
										} else {
											var smInstallToken = _SMtoken?.accessToken + ''

											var teamsAdminServiceManagement = {
												CustomerID: loggedInUser.customerID,
												GraphToken: smInstallToken,
											}

											var betaObj: BetaObject = {
												Content: await toBetaString(
													JSON.stringify(teamsAdminServiceManagement)
												),
											}

											await postObjectAndReturnData({
												url: 'AddTeamsAdminForServiceManagement',
												dataObject: betaObj,
											})
												.unwrap()
												.catch(() => {
													isInstallSuccessful = false
												})
										}

										await msalSMInstallInstance
											.logoutRedirect({
												onRedirectNavigate: () => {
													// Return false to stop navigation after local logout
													return false
												},
											})
											.then(async () => {
												if (!isInstallSuccessful) {
													showErrorToast(smInstallErrorMessage)
												} else {
													showSuccessToast(
														'Service Manager results can take up to 8 hours to reflect'
													)

													//If SM is being installed for a specific service, then reset SM status in DB
													if (
														smInstallLocation ===
														SMInstallOnlyLocation.TeamsDRCard
													) {
														await resetSMStatus()

														await runSMChecksForCustomer(
															toBetaString(loggedInUser.customerID + '')
														).unwrap()

														// show SM success on Teams DR card
														setReadyForSM(false)
														setSMLoadingPercentage(100)
														setTeamsDRCardDescription('')
														setSMFailure(false)

														await getLicenseDetails(
															msServiceToUse,
															tenantProvisioningConfig
														)
													}

													//manually insert 0 counts for SM reports for customer's default MSTeams Service
													await insertSMReportRecord()
													setRequiresSMInstall(false)
												}
											})
									}
								})
								.catch(async (error) => {
									showErrorToast(smInstallErrorMessage)

									if (!(error?.errorMessage + '').includes('User cancelled')) {
										await addErrorLog({
											ActionPerformed:
												'Generic Portal Service manager install only and token generation',
											ErrorInfo: `Customer ${
												loggedInUser.customerID
											} SM install only or token generation failure: ${JSON.stringify(
												error
											)}`,
										})
									}
								})
						})
				}
			})
			.catch(async (error) => {
				showErrorToast(smErrorMessage)

				if (!(error?.errorMessage + '').includes('User cancelled')) {
					await addErrorLog({
						ActionPerformed:
							'Generic Portal Service manager consent only and token generation',
						ErrorInfo: `Customer ${
							loggedInUser.customerID
						} SM consent only or token generation failure: ${JSON.stringify(
							error
						)}`,
					})
				}
			})
		setSMCardLoading(false)
	}

	const installSMAndStartSDAProcess = async (
		_isReconfiguringTeamsDR: boolean
	) => {
		var smErrorMessage = ' This is required to do Teams DR configuration.'

		var msalSMInstance = new PublicClientApplication({
			auth: {
				clientId: process.env.REACT_APP_SM_SETUP_CLIENT_ID + '',
			},
			system: {
				allowRedirectInIframe: true,
			},
		})

		await msalSMInstance.initialize()

		const SMApplogInRequest = {
			scopes: ['Organization.Read.All'],
			prompt: 'select_account',
		}

		await msalSMInstance
			.loginPopup(SMApplogInRequest)
			.then(async (authenticationResult) => {
				var accInfo = authenticationResult.account

				if (accInfo) {
					var _SMtoken = await msalSMInstance.acquireTokenSilent({
						scopes: GraphScopes,
						account: accInfo,
					})

					if ((_SMtoken?.accessToken + '').length < 1) {
						showErrorToast(smErrorMessage)
					} else {
						setSMToken(_SMtoken?.accessToken + '')
					}

					if (!_isReconfiguringTeamsDR) {
						await resetSMStatus()
					}

					await msalSMInstance
						.logoutRedirect({
							onRedirectNavigate: () => {
								// Return false to stop navigation after local logout
								return false
							},
						})
						.then(async () => {
							dispatch(setConfirmedAccount(GetEmptyAccountInfo()))

							if (isAuthenticated) {
								await instance.logoutRedirect({
									onRedirectNavigate: () => {
										// Return false to stop navigation after local logout
										return false
									},
								})
							}
							setIsDoingSDA(true)
							setIsReconfiguringTeamsDR(_isReconfiguringTeamsDR)
						})
				}
			})
			.catch(async (error) => {
				showErrorToast(smErrorMessage)

				if (!(error?.errorMessage + '').includes('User cancelled')) {
					await addErrorLog({
						ActionPerformed:
							'Generic Portal Service manager consent and token generation',
						ErrorInfo: `Customer ${
							loggedInUser.customerID
						} SM consent or token generation failure: ${JSON.stringify(error)}`,
					})
				}
			})
	}

	const resetSMStatus = async () => {
		var SMStatus: ServiceManagementStatus = {
			CustomerID: loggedInUser.customerID,
			ServiceID: selectedTeamsServiceID,
		}
		var smStatusToDeleteObj = {
			ServiceManagementStatus: SMStatus,
		}

		await modifyData({
			FileAndFunctionName: 'CustomerDashboardCards.tsx: resetSMStatus()',
			QueryURL: `DeleteV2?Params=ServiceManagementStatus`,
			ErrorMessage: 'An error occurred when Resetting Customer SM Status',
			QueryObj: smStatusToDeleteObj,
			LogErrorToDB: true,
			UserName: loggedInUser.email,
			SuccessMessage: ' Customer SM Status',
			ShowErrorMessage: false,
			ShowSuccessMessage: false,
		}).then(async () => {
			var smStatusToAdd: ServiceManagementStatus = {
				CustomerID: loggedInUser.customerID,
				ServiceID: selectedTeamsServiceID,
				JobID: 'ManualAdd - Generic Portal Consent',
				JobStatus: 'Success',
				Description: 'ManualAdd - Generic Portal Consent',
				LogTime: getUTCDateTimeNow(),
			}

			var smStatusToAddObj = {
				ServiceManagementStatus: smStatusToAdd,
			}

			await modifyData({
				FileAndFunctionName: 'CustomerDashboardCards.tsx: resetSMStatus()',
				QueryURL: `AddV2?Params=ServiceManagementStatus`,
				ErrorMessage: 'An error occurred when Adding Manual Customer SM Status',
				QueryObj: smStatusToAddObj,
				LogErrorToDB: true,
				UserName: loggedInUser.email,
				SuccessMessage: 'Sucessfully Added Manual Customer SM Status',
				ShowErrorMessage: false,
				ShowSuccessMessage: false,
			})
		})
	}

	const insertSMReportRecord = async () => {
		var msServiceIDToUse = ''

		if (selectedTeamsServiceID.length > 0) {
			msServiceIDToUse = selectedTeamsServiceID
		} else {
			if (customerTeamsServices.length > 0) {
				msServiceIDToUse = customerTeamsServices[0]?.ServiceID + ''
			}
		}

		var SMReport: ServiceManagementReport = {
			CustomerID: loggedInUser.customerID,
			TenantEnabledUserCount: 0,
			SipcomPlatformUserCount: 0,
			TenantCallQueueCount: 0,
			DRSIPSERZ1: 0,
			DRSIPSERZ2: 0,
			DRSIPSERZ3: 0,
			DRSIPSERZ4: 0,
			DRSIPSERZ5: 0,
			LogTime: getUTCDateTimeNow(),
			NoAllocatedZone: 0,
			GlobalVRP: false,
			GlobalPSTN: '',
			HALODRUsers: 0,
			HALODRAutoAttendants: 0,
			ServiceID: msServiceIDToUse,
		}

		var smReportToAddObj = {
			ServiceManagementReport: SMReport,
		}

		await modifyData({
			FileAndFunctionName: 'CustomerDashboardCards.tsx: insertSMReportRecord()',
			QueryURL: `AddV2?Params=ServiceManagementReport`,
			ErrorMessage: 'An error occurred when Adding Manual Customer SM Report',
			QueryObj: smReportToAddObj,
			LogErrorToDB: true,
			UserName: loggedInUser.email,
			SuccessMessage: 'Sucessfully Added Manual Customer SM Report',
			ShowErrorMessage: false,
			ShowSuccessMessage: false,
		})
	}

	return (
		<Box className='dashboard-container'>
			<Box className='dashboard-card-container'>
				{/* SM Card  */}
				<AnimatedBox
					success={true}
					children={
						<SMNumberDisplayBox
							title='HALO Utilization'
							description=''
							smAutoAttendantsCount={smAutoAttendantsCount}
							smCallQueuesCount={smCallQueuesCount}
							smTeamsDRUsersCount={smTeamsDRUsersCount}
							smTotalEndpoints={smTotalEndpoints}
							loading={smCardLoading}
							requiresSMInstall={requiresSMInstall}
							onSMInstall={() => {
								installSMOnly(SMInstallOnlyLocation.SMCard)
							}}
						/>
					}
				/>

				{
					/* If user has just one teams service or the user has more than one teams service and they have selected one, then show Licenses and Teams DR Card */
					customerTeamsServices.length > 0 &&
						(customerTeamsServices.length === 1 ||
							(customerTeamsServices.length > 1 &&
								selectedTeamsServiceID.length > 0)) && (
							<>
								{/* Licenses Card  */}
								{customerTeamsServices.length > 0 && licenseOverview && (
									<AnimatedBox
										success={true}
										children={
											<LicensesDisplayBox
												licensesLoading={licensesLoading}
												title={`Microsoft Licenses (${selectedService})`}
												totalUserLicenses={licenseOverview?.TotalLicenses}
												availableUserLicenses={
													licenseOverview?.AvailableLicenses
												}
												totalTeamsPhoneLicenses={
													licenseOverview?.TotalTeamsPhoneStandardLicenses
												}
												availableTeamsPhoneLicenses={
													licenseOverview?.AvailableTeamsPhoneStandardLicenses
												}
											/>
										}
									/>
								)}

								{/* Teams DR Card  */}
								<AnimatedBox
									success={true}
									children={
										<>
											<SimpleCard
												icon={
													<GroupsIcon
														style={{ color: theme.palette.primary.main }}
													/>
												}
												title='Service Configuration'
												description='Microsoft Teams Direct Routing'
												showAction={false}
											/>

											{/* Allow user to change the teams service they've selected */}
											{selectedService.length > 0 &&
												customerTeamsServices.length > 1 &&
												!isDoingSDA &&
												((readyForSDA && sdaLoadingPercentage === 100) ||
													(sdaCompleted && sdaLoadingPercentage === 100) ||
													sdaIssue) && (
													<Box
														className='centerBox'
														sx={{
															marginTop: '10px',
														}}>
														<Tooltip title='Select another MS Teams Service'>
															<Button
																sx={{
																	textTransform: 'none',
																	marginBottom: '10px',
																	padding: '5px',
																}}
																startIcon={
																	<ChangeCircleIcon
																		sx={{
																			color: 'text.primary',
																		}}
																	/>
																}
																onClick={() => {
																	resetTeamsServiceSelection()
																}}>
																{`MS Teams Service: ${selectedService}`}
															</Button>
														</Tooltip>
													</Box>
												)}

											{/*Show Teams DR Card */}
											{customerTeamsServices.length >= 1 &&
												selectedTeamsServiceID.length > 0 &&
												(sdaLoadingPercentage < 100 &&
												!sdaIssue &&
												!sdaCompleted ? (
													<Box>
														<Box
															className='centerBox'
															sx={{
																marginTop: '5px',
															}}>
															{selectedService.length > 0 && (
																<Typography component='p'>
																	{`MS Teams Service: ${selectedService}`}
																</Typography>
															)}
														</Box>

														<Box
															className='centerBox'
															sx={{
																marginTop: '5px',
															}}>
															<Typography
																component='h4'
																sx={{ marginTop: '10px' }}>
																Service Overview
															</Typography>
														</Box>

														{/* Loader Box */}
														<Box>
															<Box
																className='centerBox'
																sx={{
																	marginTop: '5px',
																}}>
																<CustomLoaderWithIcon
																	icon={
																		<Box className='teamsImageBox'>
																			<img
																				src={require('../../../../../../assets/images/distinct/msTeams/tdr.png')}
																				alt='Teams Icon for Teams Direct Routing'></img>
																		</Box>
																	}
																	progressValue={sdaLoadingPercentage}
																	error={false}
																/>
															</Box>

															<Box
																className='centerBox'
																sx={{
																	marginTop: '5px',
																}}>
																<Typography component='h5'>
																	{sdaLoadingTitle}
																</Typography>
															</Box>

															<Box
																className='centerBox'
																sx={{
																	marginTop: '5px',
																}}>
																<Typography component='p'>
																	{sdaLoadingDescription}
																</Typography>
															</Box>
														</Box>
													</Box>
												) : (
													<>
														{isDoingSDA ? (
															<Box>
																{/* Show SDA Component*/}
																<SDAProvisioning
																	_tenantProvisioningConfig={
																		tenantProvisioningConfig
																	}
																	customerID={loggedInUser.customerID}
																	isReprovisioning={isReconfiguringTeamsDR}
																	sdaOrderSKUInfo={sdaOrderSKUInfo}
																	smToken={SMToken}
																	teamsServiceID={selectedTeamsServiceID}
																	reloadDashboard={reloadDashboardAfterSDA}
																/>
															</Box>
														) : (
															selectedTeamsServiceID.length > 0 &&
															sdaLoadingPercentage === 100 && (
																<Box>
																	{/* Show Teams DR status of selected Service*/}
																	<Box className='centerBox'>
																		<Typography component='h4'>
																			Service Overview
																		</Typography>
																	</Box>

																	<Box>
																		{sdaIssue ? (
																			<Box className='teamsImageBox-sml'>
																				<img
																					src={require('../../../../../../assets/images/distinct/msTeams/service-config-failure-img.png')}
																					alt='teams-service-configuration-failure-img'
																				/>
																			</Box>
																		) : !sdaIssue &&
																		  sdaCompleted &&
																		  !smFailure &&
																		  smLoadingPercentage === 100 ? (
																			<Box className='teamsConfigImageBox'>
																				<img
																					src={require('../../../../../../assets/images/distinct/msTeams/teamsServiceSuccess.png')}
																					alt='teams-configured-img'
																				/>
																			</Box>
																		) : (
																			((!sdaIssue && readyForSDA) ||
																				smFailure) && (
																				<Box
																					className='centerBox'
																					sx={{
																						marginTop: '5px',
																					}}>
																					<VerifiedIcon
																						sx={{
																							color: theme.palette.primary.main,
																							fontSize: '35px',
																						}}
																					/>
																				</Box>
																			)
																		)}
																	</Box>
																</Box>
															)
														)}
													</>
												))}

											{/* Teams DR SM status */}
											{sdaLoadingPercentage === 100 && sdaCompleted && (
												<Box>
													{smLoadingPercentage < 100 &&
													!smFailure &&
													!readyForSM ? (
														<Box>
															<Box className='centerBox'>
																<Typography component='h4'>
																	Service Configuration
																</Typography>
															</Box>

															{/* Loader */}
															<Box className='centerBox'>
																<CircularProgress size={52} color='inherit' />
															</Box>
														</Box>
													) : (
														!isDoingSDA && (
															<Box sx={{ marginTop: '10px' }}>
																<Box className='centerBox'>
																	<Typography component='h4'>
																		Service Configuration
																	</Typography>
																</Box>

																<Box>
																	{smFailure && !readyForSM ? (
																		<Box className='teamsImageBox-sml'>
																			<img
																				src={require('../../../../../../assets/images/distinct/msTeams/service-config-failure-img.png')}
																				alt='teams-service-configuration-failure-img'
																			/>
																		</Box>
																	) : readyForSM ? (
																		<Box className='centerBox'>
																			<VerifiedIcon
																				sx={{
																					color: theme.palette.primary.main,
																					fontSize: '35px',
																				}}
																			/>
																		</Box>
																	) : (
																		!smFailure &&
																		sdaLoadingPercentage === 100 && (
																			<Box
																				className='teamsImageBox'
																				sx={{
																					marginTop: '5px',
																					padding: '10px',
																				}}>
																				<img
																					src={require('../../../../../../assets/images/distinct/msTeams/service-config-success-img.png')}
																					alt='teams-service-configuration-success-img'
																				/>
																			</Box>
																		)
																	)}
																</Box>
															</Box>
														)
													)}
												</Box>
											)}

											{/* Description */}
											{teamsDRCardDescription.length > 0 &&
												!isDoingSDA &&
												sdaLoadingPercentage === 100 && (
													<Box>
														<Box
															className='centerBox'
															sx={{
																marginTop: '10px',
															}}>
															<Typography component='p'>
																{teamsDRCardDescription}
															</Typography>
															{readyForSDA && (
																<Box sx={{ marginTop: '-8px' }}>
																	<Tooltip
																		title='Please ensure that pop-ups are enabled in your browser before
																	proceeding'>
																		<span>
																			<IconButton>
																				<WarningAmberIcon
																					sx={{ color: 'orange' }}
																				/>
																			</IconButton>
																		</span>
																	</Tooltip>
																</Box>
															)}
														</Box>

														{/* Button(s) */}
														{/* Ready for SDA */}
														{readyForSDA && !sdaIssue && (
															<Box className='centerBox'>
																<Button
																	onClick={() => {
																		installSMAndStartSDAProcess(false)
																	}}
																	variant='contained'
																	sx={{ maxWidth: '320px', marginTop: '10px' }}>
																	Configure Teams Direct Routing
																</Button>
															</Box>
														)}
														{/* SM Failure */}
														{smFailure && (
															<Box
																className='centerBox'
																sx={{
																	marginTop: '10px',
																}}>
																<Button
																	sx={{ maxWidth: '500px' }}
																	onClick={() => {
																		installSMAndStartSDAProcess(true)
																	}}
																	variant='contained'>
																	Reconfigure Teams Direct Routing
																</Button>
															</Box>
														)}
														{/* SM Configure */}
														{readyForSM && (
															<Box className='centerBox'>
																{!smCardLoading ? (
																	<Button
																		sx={{
																			marginTop: '10px',
																		}}
																		variant='contained'
																		onClick={() => {
																			installSMOnly(
																				SMInstallOnlyLocation.TeamsDRCard
																			)
																		}}>
																		Install Service Manager
																	</Button>
																) : (
																	<Box className='centerBox'>
																		<CircularProgress
																			sx={{
																				marginTop: '5px',
																			}}
																			size={35}
																			color='inherit'
																		/>
																	</Box>
																)}
															</Box>
														)}
													</Box>
												)}
										</>
									}
								/>
							</>
						)
				}

				{
					/* if the user has more than one teams service and they haven't selected one, then allow them to select one to use*/
					customerTeamsServices.length > 1 &&
						selectedTeamsServiceID.length === 0 && (
							<AnimatedBox
								success={true}
								children={
									<>
										<SimpleCard
											icon={
												<GroupsIcon
													style={{ color: theme.palette.primary.main }}
												/>
											}
											title='Microsoft Teams Direct Routing'
											description=''
											showAction={false}
										/>

										<TeamsServiceSelection
											teamsDRServiceValueDisplayOption={
												teamsDRServiceValueDisplayOption
											}
											confirmSelection={confirmMSTeamsService}
											teamsServiceList={customerTeamsServices}
											serviceList={serviceList}
										/>
									</>
								}
							/>
						)
				}

				{/* Learn more */}
				<LearnMore />
			</Box>
		</Box>
	)
}
