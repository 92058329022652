import { usePostBetaObjectWithoutRefetchMutation } from '../../../../services/proxyAPIData'
import { FilteredOrderStatus } from '../../../enums/enums'
import {
	toAlphaString,
	toBetaString,
} from '../../../helperFunctions/helperFunctions'
import {
	BetaAPIMutation,
	BetaObject,
	DataResponse,
	FilteredOrderResponse,
	FilterRequest,
	OrderResponse,
} from '../../../interfaces/APIModels'
import { ErrorHandling } from '../../ErrorHandling/ErrorHandling'

const FilterOrdersHook = () => {
	//Proxy API calls
	const [postWithoutRefetch] = usePostBetaObjectWithoutRefetchMutation()

	const addErrorLog = ErrorHandling()

	const retrieveFilteredOrders = async (
		requestObj: FilterRequest
	): Promise<OrderResponse> => {
		//Intitial response object
		let responseObj: OrderResponse = {
			Status: FilteredOrderStatus.NoRecords,
		}

		try {
			// Encrypt request
			const betaObj: BetaObject = {
				Content: toBetaString(JSON.stringify(requestObj)),
			}

			// API Mutation Obj
			const betaApiMutation: BetaAPIMutation = {
				BetaObject: betaObj,
				QueryParam: 'FilterOrders',
			}

			const response = await postWithoutRefetch(betaApiMutation).unwrap()

			if (response?.Content) {
				// Convert to Data Response
				const dataResponse = JSON.parse(
					await toAlphaString(response.Content)
				) as DataResponse

				if (
					dataResponse &&
					Number(dataResponse.Count) > 0 &&
					dataResponse.Obj
				) {
					// Get response
					const orderResponse = dataResponse.Obj as FilteredOrderResponse

					if (orderResponse && Number(orderResponse.TotalCount) > 0) {
						// Success return
						responseObj.Status = FilteredOrderStatus.Success
						responseObj.FilteredOrderResponse = orderResponse
					}
				}
			}
		} catch (error) {
			// Handle error
			const errorMessage = `An error occurred when retrieving orders from Filter Order Endpoint: ${error}`
			responseObj.Status = FilteredOrderStatus.Failure

			// Log Error to DB
			await addErrorLog({
				ActionPerformed:
					'Generic Portal Service manager install only and token generation',
				ErrorInfo: `Generic Portal ${errorMessage}`,
			})
		}

		return responseObj
	}

	return {
		retrieveFilteredOrders,
	}
}

export default FilterOrdersHook
