import { useEffect, useState } from 'react'
import LoadingBox from '../../displays/LoadingBox/LoadingBox'
import {
	AddressMap,
	Customer,
	CustomerPartner,
	DDI,
	DDIStatus,
} from '../../../../utils/interfaces/DBModels'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import {
	DDIStatuses,
	ReturnTypes,
	Roles,
	TrueFalseAny,
} from '../../../../utils/enums/enums'
import {
	BetaAPIMutation,
	BetaObject,
	DataResponse,
	DDIPaginationRequest,
	DDIPaginationResponse,
	DDIPaginationResponseResult,
	DDIRangesPaginationResponse,
	DDIRangesPaginationResponseResult,
} from '../../../../utils/interfaces/APIModels'
import TNOverviewDisplay from './TNOverviewDisplay/TNOverviewDisplay'
import UseCrud from '../../../../utils/customHooks/APICalls/UseCrud'
import {
	DDIDisplay,
	DDIRangeDisplay,
	IDValueModel,
} from '../../../../utils/interfaces/ComponentModels'
import EmptyDisplay from '../../displays/EmptyDisplay/EmptyDisplay'
import { usePostBetaObjectWithoutRefetchMutation } from '../../../../services/proxyAPIData'
import {
	toBeta,
	showErrorToast,
	toAlphaString,
} from '../../../../utils/helperFunctions/helperFunctions'

const TNOverview = ({
	numberOfRows,
	customerSpecificID,
}: {
	numberOfRows: number
	customerSpecificID?: string
}) => {
	const [apiCallsDone, setAPICallsDone] = useState(false)
	const [hasRows, setHasRows] = useState(false)

	// Global variables
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)

	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	const [postFilterDDIs] = usePostBetaObjectWithoutRefetchMutation()

	const [postFilterDDIRanges] = usePostBetaObjectWithoutRefetchMutation()

	// Display constants
	const noValueSelectedNumericValue = -999
	const noValueSelectedStringValue = '---'
	const pageSize = 10
	const [IDValueList] = useState([
		{
			ID: Number(TrueFalseAny.False),
			Value: `${TrueFalseAny[TrueFalseAny.False]}`,
		},
		{
			ID: Number(TrueFalseAny.True),
			Value: `${TrueFalseAny[TrueFalseAny.True]}`,
		},
		{
			ID: Number(TrueFalseAny.Any),
			Value: `${TrueFalseAny[TrueFalseAny.Any]}`,
		},
	] as IDValueModel[])

	// Arrays
	const [tnList, setTNList] = useState([] as DDI[])
	const [addressMapList, setAddressMapList] = useState([] as AddressMap[])
	const [ddiStatusList, setDDIStatusList] = useState([] as DDIStatus[])
	const [tnDisplay, setTNDisplay] = useState([] as DDIDisplay[])
	const [tnRangeDisplay, setTNRangeDisplay] = useState([] as DDIRangeDisplay[])

	const [fullCustomerList, setFullCustomerList] = useState([] as Customer[])
	const [customerList, setCustomerList] = useState([] as Customer[])

	const [ddiValue, setDDIValue] = useState(noValueSelectedStringValue)
	const [addressLine1Value, setAddressLine1Value] = useState(
		noValueSelectedStringValue
	)
	const [ingressService, setIngressService] = useState(
		noValueSelectedStringValue
	)
	const [egressService, setEgressService] = useState(noValueSelectedStringValue)
	const [customerID, setCustomerID] = useState(noValueSelectedStringValue)
	const [ddiStatusID, setDDIStatusID] = useState(noValueSelectedNumericValue)
	const [isDDIActive, setIsDDIActive] = useState(Number(TrueFalseAny.Any))

	//DDI Ranges Filter Variables
	const [rangeStart, setRangeStart] = useState(noValueSelectedStringValue)
	const [rangeEnd, setRangeEnd] = useState(noValueSelectedStringValue)

	//Constant
	const [maxPageNo, setMaxPageNo] = useState(0)
	const [totalRecords, setTotalRecords] = useState(0)

	const [ddiRangesMaxPageNo, setDDIRangesMaxPageNo] = useState(999)
	const [ddiRangesTotalRecords, setDDIRangesTotalRecords] = useState(9990)

	// Flags
	const [ddiCallMade, setDDICallMade] = useState(false)
	const [loadingData, setLoadingData] = useState(false)
	const [filterDataLoaded, setFilterDataLoaded] = useState(false)
	const [filterLoading, setFilterLoading] = useState(false)

	// Hooks
	const { fetchData } = UseCrud()

	useEffect(() => {
		// Make call to get DDIs
		if (!ddiCallMade && !loadingData) {
			setDDICallMade(true)
			getInitialData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		ddiCallMade,
		loadingData,
		tnDisplay,
		tnRangeDisplay,
		customerSpecificID,
		ddiRangesMaxPageNo,
		ddiRangesTotalRecords,
		maxPageNo,
		totalRecords,
	])

	// GET: Retrieve Data for Filters and initial DDI list
	const getInitialData = async (): Promise<void> => {
		var requestURL: string = ''

		// Loading
		setLoadingData(true)

		var _customerList = [
			{ CustomerID: noValueSelectedStringValue, CustomerName: 'Any' },
		] as Customer[]

		if (!filterDataLoaded) {
			var _partnerList = [
				{
					CustomerPartnerID: noValueSelectedNumericValue,
					CustomerPartnerName: 'Any',
				},
			] as CustomerPartner[]

			var _ddiStatusList = [
				{ DDIStatusID: noValueSelectedNumericValue, DDIStatusName: 'Any' },
			] as DDIStatus[]

			// Set customer ID to use based on role or passed in parameter
			var customerIDToUse = ''
			if (roleID === Roles.CustomerAdmin) {
				customerIDToUse = loggedInUser.customerID
			}
			if (customerSpecificID && customerSpecificID.length > 0) {
				customerIDToUse = customerSpecificID
			}

			// Check on customerID - If there is a value then filter based on it else get all TNs
			if (customerIDToUse.length > 0) {
				requestURL = `GetV2?Params=DDI.DDIRange.Where(DDIRange.CustomerID = '${customerIDToUse}'), Customer.Where(Customer.CustomerID = '${customerIDToUse}'), DDIStatus.All(), DDIType.All(), AddressMap.Address.State.Country.Where(AddressMap.CustomerID = '${customerIDToUse}')`
			} else {
				requestURL = `GetV2?Params=DDI.DDIRange.Customer.Where(Customer.CustomerPartnerID= '${partnerID}'), Customer.Where(Customer.CustomerPartnerID= '${partnerID}'), DDIStatus.All(), DDIType.All(), AddressMap.Customer.Address.State.Country.Where(Customer.CustomerPartnerID = '${partnerID}')`
			}

			// Make call based on request URL
			var dataResponse = (await fetchData({
				FileAndFunctionName: 'TNOverview.tsx: getInitialData()',
				QueryURL: requestURL,
				ErrorMessage: 'An error occurred when getting TN information',
				ShowErrorToast: false,
				LogErrorToDB: true,
				ReturnType: ReturnTypes.ObjectOrList,
			})) as DataResponse

			if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
				_partnerList = _partnerList.concat(
					dataResponse.Obj?.CustomerPartnerList as CustomerPartner[]
				)
				_customerList = _customerList.concat(
					dataResponse.Obj?.CustomerList as Customer[]
				)
				_ddiStatusList = _ddiStatusList.concat(
					dataResponse.Obj?.DDIStatusList as DDIStatus[]
				)

				var _addressMapListToAdd = dataResponse.Obj
					.AddressMapList as AddressMap[]
				setAddressMapList(_addressMapListToAdd)

				var _ddiList = dataResponse.Obj.DDIList as DDI[]
				if (_ddiList && _ddiList.length > 0) {
					setHasRows(true)
					setTNList(_ddiList)
				}

				setFilterDataLoaded(true)
			}

			setCustomerList(
				_customerList.sort((a, b) =>
					a.CustomerName!.localeCompare(b.CustomerName!)
				)
			)
			setFullCustomerList(
				_customerList.sort((a, b) =>
					a.CustomerName!.localeCompare(b.CustomerName!)
				)
			)
			setDDIStatusList(_ddiStatusList)
		} else {
			_customerList = fullCustomerList
		}

		if (fullCustomerList.length > 0) {
			setCustomerList(
				fullCustomerList.sort((a, b) =>
					a.CustomerName!.localeCompare(b.CustomerName!)
				)
			)
		}

		handleResetFilterFields()

		//Get initial Data
		var initialDDIPaginationReq: DDIPaginationRequest = {
			pageNo: 1,
			pageSize: pageSize,
		}

		var betaDataObj = await toBeta(initialDDIPaginationReq)

		var betaObj: BetaObject = {
			Content: betaDataObj,
		}

		var betaApiMutation: BetaAPIMutation = {
			BetaObject: betaObj,
			QueryParam: 'FilterDDIs',
		}

		await postFilterDDIs(betaApiMutation)
			.unwrap()
			.then(async (response) => {
				if (response?.Content) {
					var dataResponse = JSON.parse(
						await toAlphaString(response?.Content)
					) as DataResponse

					if (dataResponse?.Obj) {
						var ddiPaginationResponse =
							dataResponse?.Obj as DDIPaginationResponse
						if (
							ddiPaginationResponse?.PaginatedResults &&
							ddiPaginationResponse.PaginatedResults.length > 0
						) {
							var ddiPaginationResponseResults =
								ddiPaginationResponse?.PaginatedResults as DDIPaginationResponseResult[]
							var _ddiDisplayList = [] as DDIDisplay[]

							ddiPaginationResponseResults.forEach((x) => {
								var _address = getAddressStringForPaginatedDDI(x)
								var _customer = _customerList.find(
									(c) => c.CustomerID === x.CustomerID
								)

								var _customerNameToUse = 'No_Customer'
								if (_customer?.CustomerName !== undefined) {
									_customerNameToUse = _customer?.CustomerName + ''
								}

								var _ddiDisplayListToAdd: DDIDisplay = {
									ID: Number(x.ID),
									DDI: x?.DDIValue + '',
									AddressFriendlyName: _address,
									IngressServiceID: x.ServiceInID,
									EgressServiceID: x.ServiceOutID,
									DDIStatus: DDIStatuses[Number(x.DDIStatusID)],
									CustomerName: _customerNameToUse,
									CustomerID: x.CustomerID,
								}

								_ddiDisplayList.push(_ddiDisplayListToAdd)

								var _ddiRangeDisplayList = [] as DDIRangeDisplay[]
								var _ddiRangeDisplayToAdd: DDIRangeDisplay = {
									DDIRangeID: Number(x.DDIRangeID),
									DDIRangeStart: x.RangeStart + '',
									DDIRangeEnd: x.RangeEnd + '',
									IsRangeActive: x.isRangeActive,
									CustomerName: _customerNameToUse,
									CustomerID: x.CustomerID + '',
								}
								_ddiRangeDisplayList.push(_ddiRangeDisplayToAdd)
								setTNRangeDisplay(_ddiRangeDisplayList)

								setTNDisplay(_ddiDisplayList)

								setMaxPageNo(
									Math.ceil(
										Number(ddiPaginationResponse?.TotalRecordsFound) / pageSize
									)
								)
								setTotalRecords(
									Number(ddiPaginationResponse?.TotalRecordsFound)
								)

								setDDIRangesMaxPageNo(
									Math.ceil(
										Number(ddiPaginationResponse?.TotalRecordsFound) / pageSize
									)
								)
								setDDIRangesTotalRecords(
									Number(ddiPaginationResponse?.TotalRecordsFound)
								)
							})
						}
					}
				}
			})
			.catch(() => {
				showErrorToast('Unable to get DDIs')
			})

		setAPICallsDone(true)

		// Loading
		setLoadingData(false)
	}

	const getAddressStringForPaginatedDDI = (
		ddiPaginatedResponseResult: DDIPaginationResponseResult
	) => {
		var _address = ''

		if ((ddiPaginatedResponseResult?.HouseNumber + '').length > 0) {
			_address += `${ddiPaginatedResponseResult.HouseNumber} `
		}

		_address += `${ddiPaginatedResponseResult.AddressLine1}, `

		if ((ddiPaginatedResponseResult?.AddressLine2 + '').length > 0) {
			_address += `${ddiPaginatedResponseResult.AddressLine2}, `
		}

		if ((ddiPaginatedResponseResult?.City + '').length > 0) {
			_address += `${ddiPaginatedResponseResult.City}, `
		}

		_address += `${ddiPaginatedResponseResult.StateName}, ${ddiPaginatedResponseResult.CountryName}`

		return _address
	}

	const handleResetFilterFields = () => {
		setDDIValue('')
		setIngressService(noValueSelectedStringValue)
		setEgressService(noValueSelectedStringValue)
		setDDIStatusID(noValueSelectedNumericValue)
		setCustomerID(noValueSelectedStringValue)
		setIsDDIActive(Number(TrueFalseAny.Any))
		setAddressLine1Value('')
	}

	const handleFilterData = async (pageNumber: number): Promise<void> => {
		// Loading
		setLoadingData(true)
		setFilterLoading(true)

		var _filters = getFilters()

		//Get initial Data
		var ddiPaginationReq: DDIPaginationRequest = {
			pageNo: pageNumber,
			pageSize: pageSize,
			filters: _filters,
		}

		var betaDataObj = await toBeta(ddiPaginationReq)

		var betaObj: BetaObject = {
			Content: betaDataObj,
		}

		var betaApiMutation: BetaAPIMutation = {
			BetaObject: betaObj,
			QueryParam: 'FilterDDIs',
		}

		await postFilterDDIs(betaApiMutation)
			.unwrap()
			.then(async (response) => {
				if (response?.Content) {
					var dataResponse = JSON.parse(
						await toAlphaString(response?.Content)
					) as DataResponse

					if (dataResponse?.Obj) {
						var ddiPaginationResponse =
							dataResponse?.Obj as DDIPaginationResponse
						if (
							ddiPaginationResponse?.PaginatedResults &&
							ddiPaginationResponse.PaginatedResults.length >= 0
						) {
							var ddiPaginationResponseResults =
								ddiPaginationResponse?.PaginatedResults as DDIPaginationResponseResult[]
							var _ddiDisplayList = [] as DDIDisplay[]
							var _ddiRangeDisplayList = [] as DDIRangeDisplay[]

							ddiPaginationResponseResults.forEach((x) => {
								var _address = getAddressStringForPaginatedDDI(x)
								var _customer = customerList.find(
									(c) => c.CustomerID === x.CustomerID
								)

								var _customerNameToUse = 'No_Customer'
								if (_customer?.CustomerName !== undefined) {
									_customerNameToUse = _customer?.CustomerName + ''
								}

								var _ddiDisplayListToAdd: DDIDisplay = {
									ID: Number(x.ID),
									DDI: x?.DDIValue + '',
									AddressFriendlyName: _address,
									IngressServiceID: x.ServiceInID,
									EgressServiceID: x.ServiceOutID,
									DDIStatus: DDIStatuses[Number(x.DDIStatusID)],
									CustomerName: _customerNameToUse,
									CustomerID: x.CustomerID,
								}

								_ddiDisplayList.push(_ddiDisplayListToAdd)

								var _ddiRangeDisplayToAdd: DDIRangeDisplay = {
									DDIRangeID: Number(x.DDIRangeID),
									DDIRangeStart: x.RangeStart + '',
									DDIRangeEnd: x.RangeEnd + '',
									IsRangeActive: x.isRangeActive,
									CustomerName: _customerNameToUse,
									CustomerID: x.CustomerID + '',
								}
								_ddiRangeDisplayList.push(_ddiRangeDisplayToAdd)
							})

							setTNDisplay(_ddiDisplayList)
							setTNRangeDisplay(_ddiRangeDisplayList)

							setMaxPageNo(
								Math.ceil(
									Number(ddiPaginationResponse?.TotalRecordsFound) / pageSize
								)
							)
							setTotalRecords(Number(ddiPaginationResponse?.TotalRecordsFound))
						}
					}
				}
			})
			.catch(() => {
				showErrorToast('Unable to get DDIs')
			})

		// Loading
		setLoadingData(false)
		setFilterLoading(false)
	}

	const getFilters = () => {
		var filters = {} as Record<string, string>

		if (partnerID !== Number(noValueSelectedNumericValue)) {
			filters['CustomerPartnerID'] = `${partnerID}`
		}

		if (customerID !== noValueSelectedStringValue) {
			filters['CustomerID'] = `${customerID}`
		}

		if (ddiStatusID !== Number(noValueSelectedNumericValue)) {
			filters['DDIStatusID'] = `${ddiStatusID}`
		}

		if (isDDIActive !== Number(TrueFalseAny.Any)) {
			filters['IsDDIActive'] = `${isDDIActive}`
		}

		if (ingressService !== noValueSelectedStringValue) {
			filters['IngessService'] = `${ingressService}`
		}

		if (egressService !== noValueSelectedStringValue) {
			filters['EgressService'] = `${egressService}`
		}

		if (addressLine1Value.length > 0) {
			filters['AddressLine1'] = `${addressLine1Value}`
		}

		if (ddiValue.length > 0) {
			filters['DDI'] = `${ddiValue}`
		}

		return filters
	}

	//Get DDI Ranges
	const handleFilterDDIRangesData = async (
		pageNumber: number
	): Promise<void> => {
		// Loading
		setLoadingData(true)
		setFilterLoading(true)

		var _filters = getDDIRangesFilters()

		//Get initial Data
		var ddiPaginationReq: DDIPaginationRequest = {
			pageNo: pageNumber,
			pageSize: pageSize,
			filters: _filters,
		}

		var betaDataObj = await toBeta(ddiPaginationReq)

		var betaObj: BetaObject = {
			Content: betaDataObj,
		}

		var betaApiMutation: BetaAPIMutation = {
			BetaObject: betaObj,
			QueryParam: 'FilterDDIRanges',
		}

		await postFilterDDIRanges(betaApiMutation)
			.unwrap()
			.then(async (response) => {
				if (response?.Content) {
					var dataResponse = JSON.parse(
						await toAlphaString(response?.Content)
					) as DataResponse

					if (dataResponse?.Obj) {
						var ddiRangesPaginationResponse =
							dataResponse?.Obj as DDIRangesPaginationResponse
						if (
							ddiRangesPaginationResponse?.PaginatedResults &&
							ddiRangesPaginationResponse.PaginatedResults.length >= 0
						) {
							var ddiRangesPaginationResponseResults =
								ddiRangesPaginationResponse?.PaginatedResults as DDIRangesPaginationResponseResult[]
							var _ddiRangesDisplayList = [] as DDIRangeDisplay[]

							ddiRangesPaginationResponseResults.forEach((x) => {
								var _ddiRangeDisplayToAdd: DDIRangeDisplay = {
									DDIRangeID: x.DDIRangeID,
									DDIRangeStart: x.RangeStart + '',
									DDIRangeEnd: x.RangeEnd + '',
									CustomerName: x.CustomerName,
									CustomerID: x.CustomerID + '',
								}
								_ddiRangesDisplayList.push(_ddiRangeDisplayToAdd)
								setRangeStart(x.RangeStart + '')
								setRangeEnd(x.RangeEnd + '')
							})
							setDDIRangesMaxPageNo(
								Math.ceil(
									Number(ddiRangesPaginationResponse?.TotalRecordsFound) /
										pageSize
								)
							)
							setDDIRangesTotalRecords(
								Number(ddiRangesPaginationResponse?.TotalRecordsFound)
							)
							setTNRangeDisplay(_ddiRangesDisplayList)
						}
					}
				}
			})
			.catch(() => {
				showErrorToast('Unable to get DDI Ranges')
			})

		// Loading
		setLoadingData(false)
		setFilterLoading(false)
	}

	const getDDIRangesFilters = () => {
		var filters = {} as Record<string, string>

		if (rangeStart !== noValueSelectedStringValue) {
			filters['RangeStart'] = `${rangeStart.replace(' ', '')}`
		}

		if (rangeEnd !== noValueSelectedStringValue) {
			filters['RangeEnd'] = `${rangeEnd.replace(' ', '')}`
		}

		if (customerID !== noValueSelectedStringValue) {
			filters['CustomerID'] = `${customerID.replace(' ', '')}`
		}

		return filters
	}

	return apiCallsDone ? (
		hasRows ? (
			// Display datagrid
			<TNOverviewDisplay
				ddiDisplay={tnDisplay}
				ddiRangeDisplay={tnRangeDisplay}
				ddiList={tnList}
				ddiStatusList={ddiStatusList}
				addressMapList={addressMapList}
				numberOfRows={numberOfRows}
				getTNs={getInitialData}
				handleFilterData={handleFilterData}
				maxPageNo={maxPageNo}
				totalRecords={totalRecords}
				filterLoading={filterLoading}
				handleFilterDDIRangesData={handleFilterDDIRangesData}
				ddiRangesMaxPageNo={ddiRangesMaxPageNo}
				ddiRangesTotalRecords={ddiRangesTotalRecords}
			/>
		) : (
			<EmptyDisplay
				title='No TNs found'
				description='There were no TNs found'
			/>
		)
	) : (
		// Show loading
		<LoadingBox title='Getting TNs' />
	)
}

export default TNOverview
