import { useCallback, useEffect, useState } from 'react'
import { DDIRangeDisplay } from '../../../../../utils/interfaces/ComponentModels'
import { AddressMap, DDI } from '../../../../../utils/interfaces/DBModels'
import {
	GridColDef,
	GridFilterModel,
	GridFooter,
	GridFooterContainer,
	GridRenderCellParams,
	GridValidRowModel,
} from '@mui/x-data-grid-pro'
import { StyledDataGrid } from '../../../../../styles/styledComponents/displays/StyledDataGrid'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import {
	Backdrop,
	Box,
	IconButton,
	Modal,
	Tooltip,
	Typography,
} from '@mui/material'
import StyledModal from '../../../../../styles/styledComponents/displays/StyledModal'
import { showErrorToast } from '../../../../../utils/helperFunctions/helperFunctions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import UseCrud from '../../../../../utils/customHooks/APICalls/UseCrud'
import DDIAddressUpdate from './DDIAddressUpdate'
import { format } from 'date-fns'
import { Roles } from '../../../../../utils/enums/enums'
import { StyledButton } from '../../../../../styles/styledComponents/inputs/StyledButton'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'

const TNRangeDisplay = ({
	ddiRangeDisplay,
	ddiList,
	addressMapList,
	numberOfRows,
	getTNs,
	handleFilterDDIRangesData,
	filterLoading,
	ddiRangesMaxPageNo,
	ddiRangesTotalRecords,
}: {
	ddiRangeDisplay: DDIRangeDisplay[]
	ddiList: DDI[]
	addressMapList: AddressMap[]
	numberOfRows: number
	getTNs: any
	handleFilterDDIRangesData: (pageNumber: number) => Promise<void>
	filterLoading: boolean
	ddiRangesMaxPageNo: number
	ddiRangesTotalRecords: number
}) => {
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)

	// Hooks
	const { modifyData } = UseCrud()

	// UseState
	const [tnRangeRows, setTNRangeRows] = useState([] as DDIRangeDisplay[])
	const [selectedDDIRangeDisplay, setSelectedDDIRangeDisplay] = useState(
		{} as DDIRangeDisplay
	)
	const [filterAddressMapList, setFilterAddressMapList] = useState(
		[] as AddressMap[]
	)

	const [currentPageNo, setCurrentPageNo] = useState(1)

	// Flags
	const [openModal, setOpenModal] = useState(false)
	const [allColumnsSet, setAllColumnsSet] = useState(false)

	// Set auto height to detail panel
	const getDetailPanelHeight = useCallback(() => 'auto', [])

	// *** Datagrid Specific Variables *** //
	// Pagination
	const [paginationModel, setPaginationModel] = useState({
		pageSize: numberOfRows,
		page: 0,
	})

	// Filters - Quick filters for the datagrid
	const [filterModel, setFilterModel] = useState<GridFilterModel>({
		items: [],
	})

	// Column Definition: Order Table
	const initialTNRangeColumns: GridColDef[] = [
		{
			field: 'DDIRangeStart',
			headerName: 'TN Start',
			hideable: false,
			flex: 1,
		},
		{
			field: 'DDIRangeEnd',
			headerName: 'TN End',
			hideable: false,
			flex: 1,
		},
		{
			field: 'PortDate',
			headerName: 'FOC Date',
			width: 220,
			hideable: false,
			flex: 1,
			renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
				<>{params.value ? format(params.value, 'MMMM dd, yyyy') : ''}</>
			),
		},
	]

	const [tnRangeColumns, setTNRangeColumns] = useState<GridColDef[]>(
		initialTNRangeColumns
	)

	useEffect(() => {
		// Set rows
		if (ddiRangeDisplay.length > 0) {
			setTNRangeRows(ddiRangeDisplay)
			if (roleID === Roles.PartnerAdmin) {
				initialTNRangeColumns.push({
					field: 'CustomerName',
					headerName: 'Customer',
					width: 220,
					hideable: false,
					flex: 1,
				})
			}
			initialTNRangeColumns.push({
				field: 'DDIRangeID',
				type: 'actions',
				cellClassName: 'actions',
				headerName: '',
				hideable: false,
				flex: 1,
				filterable: false,
				sortable: false,
				getActions: ({ id }) => {
					return [
						<>
							<Tooltip title='Allocate Location to TN Range'>
								<span>
									<IconButton
										id='action-button'
										onClick={() => {
											handleUpdateTNRangeAddressClick(Number(id))
										}}>
										<SettingsOutlinedIcon />
									</IconButton>
								</span>
							</Tooltip>
						</>,
					]
				},
			})
			setTNRangeColumns(initialTNRangeColumns)
			setAllColumnsSet(true)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ddiList, ddiRangeDisplay])

	const handleOpenModal = () => {
		setOpenModal(true)
	}

	const handleCloseModal = () => {
		setOpenModal(false)
	}

	// Handle functions
	const handleUpdateTNRangeAddressClick = async (ddiRangeID: number) => {
		if (ddiRangeID) {
			var _selectedDDIRangeDisplay = ddiRangeDisplay.find(
				(x) => x.DDIRangeID === ddiRangeID
			)

			if (_selectedDDIRangeDisplay) {
				setSelectedDDIRangeDisplay(_selectedDDIRangeDisplay)
			}

			// filter address list to addresses by customer and dialing code
			var filteredAddresses = addressMapList.filter((x) => {
				var dialingCode = x.Address?.State?.Country?.CountryDiallingCode
				return (
					dialingCode ===
						_selectedDDIRangeDisplay?.DDIRangeStart?.substring(
							0,
							dialingCode?.length
						) && _selectedDDIRangeDisplay?.CustomerID === x.CustomerID
				)
			})
			setFilterAddressMapList(filteredAddresses)
			setOpenModal(true)
			handleOpenModal()
		}
	}

	const handleAdressUpdate = async (
		addressMapID: number,
		ddiRangeID?: number
	) => {
		if (addressMapID && ddiRangeID) {
			var matchingAddressMap = addressMapList.find(
				(x) => x.AddressMapID === Number(addressMapID)
			)
			var matchingDDIs = ddiList.filter((x) => x.DDIRangeID === ddiRangeID)
			var ddisNotMatchingAddressID = matchingDDIs.filter(
				(x) => x.AddressID !== matchingAddressMap?.AddressID
			)
			if (ddisNotMatchingAddressID && ddisNotMatchingAddressID.length > 0) {
				var ddisToUpdate: DDI[] = []
				ddisNotMatchingAddressID.map(function (val) {
					ddisToUpdate.push({
						ID: val.ID,
						AddressID: matchingAddressMap?.AddressID,
					})
				})

				try {
					// Post to DB
					var postSuccess = await modifyData({
						UserName: loggedInUser.email,
						FileAndFunctionName: `TNRangeDisplay.tsx: handleAdressUpdate()`,
						QueryURL: 'UpdateV2?Params=DDI:List',
						QueryObj: {
							DDIList: ddisToUpdate,
						},
						ShowSuccessMessage: true,
						SuccessMessage: `Successfully updated TN Range location`,
						ShowErrorMessage: false,
						ErrorMessage: `Failed to update TN Range location`,
						LogErrorToDB: true,
					})

					if (postSuccess) {
						await getTNs()
						handleCloseModal()
					}
				} catch (error) {
					showErrorToast(
						`An error occurred when trying to update TN Range location`
					)
				}
			}
		}
	}

	// Handle page navigation
	const handlePrevPageClick = async () => {
		if (currentPageNo - 1 >= 0) {
			setCurrentPageNo(currentPageNo - 1)
			await handleFilterDDIRangesData(currentPageNo - 1)
		}
	}

	const handleNextPageClick = async () => {
		if (currentPageNo + 1 <= ddiRangesMaxPageNo) {
			setCurrentPageNo(currentPageNo + 1)
			await handleFilterDDIRangesData(currentPageNo + 1)
		}
	}

	//1 - Custom Footer
	function CustomFooter() {
		return !filterLoading ? (
			<GridFooterContainer className='order-footer'>
				<GridFooter sx={{ borderTop: 'none' }} />
				<Box className='order-footer-content'>
					{/* Total rows */}
					<Box className='page-info'>
						<Typography>
							Page <strong>{currentPageNo}</strong> - {ddiRangesMaxPageNo} of{' '}
							{ddiRangesTotalRecords} Results
						</Typography>
					</Box>
					{/* Pagination */}
					<Box className='order-pagination'>
						{/* Previous */}
						<Tooltip title='Previous page'>
							<span>
								<StyledButton
									disabled={currentPageNo === 1}
									startIcon={<NavigateBeforeIcon />}
									onClick={() => handlePrevPageClick()}
								/>
							</span>
						</Tooltip>
						{/* Next */}
						<Tooltip title='Next Page'>
							<span>
								<StyledButton
									disabled={currentPageNo === ddiRangesTotalRecords}
									startIcon={<NavigateNextIcon />}
									onClick={() => handleNextPageClick()}
								/>
							</span>
						</Tooltip>
					</Box>
				</Box>
			</GridFooterContainer>
		) : (
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={filterLoading}
			/>
		)
	}

	return (
		<>
			{/* Datagrid: TNs */}
			{allColumnsSet && (
				<StyledDataGrid
					rows={tnRangeRows}
					columns={tnRangeColumns}
					editMode='row'
					checkboxSelection={false}
					paginationModel={paginationModel}
					pageSizeOptions={[5]}
					slots={{
						footer: CustomFooter,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					getRowId={(row) => row.DDIRangeID}
					autoHeight={true}
					rowSelection={false}
					getDetailPanelHeight={getDetailPanelHeight}
					filterModel={filterModel}
					onFilterModelChange={(newFilterModel) =>
						setFilterModel(newFilterModel)
					}
					hideFooterRowCount
					disableColumnMenu
				/>
			)}
			{/* Modal to update range locations */}
			<Modal open={openModal}>
				<StyledModal width={700}>
					<DDIAddressUpdate
						isRange={true}
						ddiRangeDisplay={selectedDDIRangeDisplay}
						addressMapList={filterAddressMapList}
						handleAdressUpdate={handleAdressUpdate}
						onClose={handleCloseModal}
					/>
				</StyledModal>
			</Modal>
		</>
	)
}

export default TNRangeDisplay
