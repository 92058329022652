import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { User } from '../../utils/interfaces/APIModels'
import {
	CustomerPartner,
	RolePermissionMap,
} from '../../utils/interfaces/DBModels'
import { TeamsUserDDIAssignment } from '../../utils/interfaces/ComponentModels'
import { TUMLicenses } from '../../utils/interfaces/StateModels'
import { AccountInfo } from '@azure/msal-browser'

// ** Declare the global state interface ** //

// Used for the theme
interface PartnerIDState {
	value: number
}

interface CustomerPartnerState {
	value: CustomerPartner
}
interface PartnerNameState {
	value: string
}

interface OrganizationIDState {
	value: string
}
// Used for calling the proxy
interface AccessTokenState {
	value: string
}

// Used for roles and permissions
interface LoggedInUserRolesPermissionsState {
	value: RolePermissionMap[]
}

interface LoggedInUserState {
	value: User
}

interface ConfirmedAccountState {
	value: AccountInfo
}

// Used for Teams User MGT to assign phone numbers
interface TeamsUserDDIAssignmentState {
	value: TeamsUserDDIAssignment[]
}

// Used for Service MGT to display UC Platform User Count
interface UCPlatformUserCountState {
	value: number
}

// Flag used to change the theme
interface InitializeSiteThemeState {
	value: boolean
}

// Used for Teams User MGT license overview
interface TUMLicensesState {
	value: TUMLicenses
}

// Used for Logo
interface LogoState {
	value: string
}

// Used to check what role the user currently has
interface RoleIDState {
	value: number
}

// Used for setting partner ID in headers when calling HALO API endpoints in the proxy
interface PartnerIDToUseState {
	value: number
}

//Pagination for Order MGT
interface OrderFilterState {
	pageNo: number
	pageSize: number
	filters: Record<string, string>
}

interface ShowTeamsUMPageState {
	value: boolean
}

// ** Set the initial value ** //
const initialPartnerIDState: PartnerIDState = {
	value: 0,
}
const initialPartnerNameState: PartnerNameState = {
	value: '',
}
const initialCustomerPartnerState: CustomerPartnerState = {
	value: {} as CustomerPartner,
}
const initialOrganizationIDState: OrganizationIDState = {
	value: '',
}
const initialAccessTokenState: AccessTokenState = {
	value: '',
}

const initialLoggedInUserRolesPermissionsState: LoggedInUserRolesPermissionsState =
	{
		value: [] as RolePermissionMap[],
	}

const initialLoggedInUserState: LoggedInUserState = {
	value: {} as User,
}

const initialTeamsUserDDIAssignmentState: TeamsUserDDIAssignmentState = {
	value: [] as TeamsUserDDIAssignment[],
}

const initialUCPlatformUserCountState: UCPlatformUserCountState = {
	value: 0,
}

const initialInitializeSiteThemeState: InitializeSiteThemeState = {
	value: false,
}

const initialTUMLicensesState: TUMLicensesState = {
	value: {
		LicenseOverview: {
			TotalAvailableLicenses: 0,
			TotalPurchasedLicenses: 0,
			TotalUsedLicenses: 0,
		},
	} as TUMLicenses,
}

const initialLogoState: LogoState = {
	value: '',
}

const initialRoleIDState: RoleIDState = {
	value: 0,
}

const initialConfirmedAccountState: ConfirmedAccountState = {
	value: {
		environment: '',
		homeAccountId: '',
		localAccountId: '',
		tenantId: '',
		username: '',
		idToken: '',
		name: '',
		nativeAccountId: '',
	} as AccountInfo,
}

const initialPartnerIDToUseState: PartnerIDToUseState = {
	value: 0,
}

const initialOrderFilterState: OrderFilterState = {
	pageNo: 1,
	pageSize: 10,
	filters: {},
}

const initialShowTeamsUMPageState: ShowTeamsUMPageState = {
	value: false,
}

// ** Create the slice ** //
export const PartnerIDSlice = createSlice({
	name: 'partnerID',
	initialState: initialPartnerIDState,
	reducers: {
		setPartnerID: (state, action: PayloadAction<number>) => {
			state.value = action.payload
		},
	},
})
export const ShowTeamsUMPageSlice = createSlice({
	name: 'showTeamsUMPage',
	initialState: initialShowTeamsUMPageState,
	reducers: {
		setShowTeamsUMPage: (state, action: PayloadAction<boolean>) => {
			state.value = action.payload
		},
	},
})
export const CustomerPartnerSlice = createSlice({
	name: 'customerPartner',
	initialState: initialCustomerPartnerState,
	reducers: {
		setCustomerPartner: (state, action: PayloadAction<CustomerPartner>) => {
			state.value = action.payload
		},
	},
})
export const PartnerNameSlice = createSlice({
	name: 'partnerName',
	initialState: initialPartnerNameState,
	reducers: {
		setPartnerName: (state, action: PayloadAction<string>) => {
			state.value = action.payload
		},
	},
})
export const OrganizationIDSlice = createSlice({
	name: 'organizationID',
	initialState: initialOrganizationIDState,
	reducers: {
		setOrganizationID: (state, action: PayloadAction<string>) => {
			state.value = action.payload
		},
	},
})
export const AccessTokenSlice = createSlice({
	name: 'accessToken',
	initialState: initialAccessTokenState,
	reducers: {
		setAccessToken: (state, action: PayloadAction<string>) => {
			state.value = action.payload
		},
	},
})

export const LoggedInUserRolesPermisionsSlice = createSlice({
	name: 'loggedInUserPermissions',
	initialState: initialLoggedInUserRolesPermissionsState,
	reducers: {
		setLoggedInUserRolesPermissions: (
			state,
			action: PayloadAction<RolePermissionMap[]>
		) => {
			state.value = action.payload
		},
	},
})

export const LoggedInUserSlice = createSlice({
	name: 'loggedInUser',
	initialState: initialLoggedInUserState,
	reducers: {
		setLoggedInUser: (state, action: PayloadAction<User>) => {
			state.value = action.payload
		},
	},
})

export const TeamsUserDDIAssignmentSlice = createSlice({
	name: 'teamsUserDDIAssignment',
	initialState: initialTeamsUserDDIAssignmentState,
	reducers: {
		setTeamsUserDDIAssignment: (
			state,
			action: PayloadAction<TeamsUserDDIAssignment[]>
		) => {
			state.value = action.payload
		},
	},
})

export const UCPlatformUserCountSlice = createSlice({
	name: 'ucPlatformUserCount',
	initialState: initialUCPlatformUserCountState,
	reducers: {
		setUCPlatformUserCount: (state, action: PayloadAction<number>) => {
			state.value = action.payload
		},
	},
})

export const InitializeSiteThemeSlice = createSlice({
	name: 'initializeSiteTheme',
	initialState: initialInitializeSiteThemeState,
	reducers: {
		setInitializeSiteTheme: (state, action: PayloadAction<boolean>) => {
			state.value = action.payload
		},
	},
})

export const TUMLicensesSlice = createSlice({
	name: 'tumLicenses',
	initialState: initialTUMLicensesState,
	reducers: {
		setTUMLicenses: (state, action: PayloadAction<TUMLicenses>) => {
			state.value = action.payload
		},
	},
})

export const LogoSlice = createSlice({
	name: 'logo',
	initialState: initialLogoState,
	reducers: {
		setLogo: (state, action: PayloadAction<string>) => {
			state.value = action.payload
		},
	},
})

export const RoleIDSlice = createSlice({
	name: 'roleID',
	initialState: initialRoleIDState,
	reducers: {
		setRoleID: (state, action: PayloadAction<number>) => {
			state.value = action.payload
		},
	},
})

export const ConfirmedAccountSlice = createSlice({
	name: 'confirmedAccount',
	initialState: initialConfirmedAccountState,
	reducers: {
		setConfirmedAccount: (state, action: PayloadAction<AccountInfo>) => {
			state.value = action.payload
		},
	},
})

export const partnerIDToUseSlice = createSlice({
	name: 'partnerID',
	initialState: initialPartnerIDToUseState,
	reducers: {
		setPartnerIDToUse: (state, action: PayloadAction<number>) => {
			state.value = action.payload
		},
	},
})

export const OrderFilterSlice = createSlice({
	name: 'orderFilter',
	initialState: initialOrderFilterState,
	reducers: {
		setOrderPageNo: (state, action: PayloadAction<number>) => {
			state.pageNo = action.payload
		},
		setOrderPageSize: (state, action: PayloadAction<number>) => {
			state.pageSize = action.payload
		},
		setOrderFilter: (state, action: PayloadAction<Record<string, string>>) => {
			state.filters = { ...state.filters, ...action.payload }
		},
		removeOrderFilter: (state, action: PayloadAction<string>) => {
			delete state.filters[action.payload]
		},
		resetOrderFilters: (state) => {
			state.filters = {}
		},
	},
})

// ** Set actions ** //
export const { setPartnerID } = PartnerIDSlice.actions
export const { setCustomerPartner } = CustomerPartnerSlice.actions
export const { setPartnerName } = PartnerNameSlice.actions
export const { setOrganizationID } = OrganizationIDSlice.actions
export const { setAccessToken } = AccessTokenSlice.actions
export const { setLoggedInUserRolesPermissions } =
	LoggedInUserRolesPermisionsSlice.actions
export const { setLoggedInUser } = LoggedInUserSlice.actions
export const { setTeamsUserDDIAssignment } = TeamsUserDDIAssignmentSlice.actions
export const { setUCPlatformUserCount } = UCPlatformUserCountSlice.actions
export const { setInitializeSiteTheme } = InitializeSiteThemeSlice.actions
export const { setTUMLicenses } = TUMLicensesSlice.actions
export const { setLogo } = LogoSlice.actions
export const { setRoleID } = RoleIDSlice.actions
export const { setConfirmedAccount } = ConfirmedAccountSlice.actions
export const { setPartnerIDToUse } = partnerIDToUseSlice.actions
export const { setShowTeamsUMPage } = ShowTeamsUMPageSlice.actions
export const {
	setOrderPageNo,
	setOrderPageSize,
	setOrderFilter,
	removeOrderFilter,
	resetOrderFilters,
} = OrderFilterSlice.actions

// Get actions
export const getPartnerID = (state: RootState) =>
	state.RootReducer.partnerIDReducer.value
export const getCustomerPartner = (state: RootState) =>
	state.RootReducer.customerPartnerReducer.value
export const getPartnerName = (state: RootState) =>
	state.RootReducer.partnerNameReducer
export const getOrganizationID = (state: RootState) =>
	state.RootReducer.organizationIDReducer
export const getTeamsUserDDIAssignment = (state: RootState) =>
	state.RootReducer.teamsUserDDIAssignmentReducer.value
export const getUCPlatformUserCount = (state: RootState) =>
	state.RootReducer.ucPlatformUserCountReducer.value
export const getInitializeSiteTheme = (state: RootState) =>
	state.RootReducer.initializeSiteThemeReducer.value
export const getTUMLicenses = (state: RootState) =>
	state.RootReducer.tumLicensesReducer.value
export const getLogo = (state: RootState) => state.RootReducer.logoReducer.value
export const getRoleID = (state: RootState) =>
	state.RootReducer.roleIDReducer.value
export const getLoggedInUserRolesPermissions = (state: RootState) =>
	state.RootReducer.loggedInUserRolesPermissionsReducer.value
export const getPartnerIDToUse = (state: RootState) =>
	state.RootReducer.partnerIDToUseReducer.value

// ** Reducers ** //
var showTeamsUMReducer = ShowTeamsUMPageSlice.reducer
var partnerIDReducer = PartnerIDSlice.reducer
var partnerNameReducer = PartnerNameSlice.reducer
var customerPartnerReducer = CustomerPartnerSlice.reducer
var organizationIDReducer = OrganizationIDSlice.reducer
var accessTokenReducer = AccessTokenSlice.reducer
var loggedInUserRolesPermissionsReducer =
	LoggedInUserRolesPermisionsSlice.reducer
var loggedInUserReducer = LoggedInUserSlice.reducer
var teamsUserDDIAssignmentReducer = TeamsUserDDIAssignmentSlice.reducer
var ucPlatformUserCountReducer = UCPlatformUserCountSlice.reducer
var initializeSiteThemeReducer = InitializeSiteThemeSlice.reducer
var tumLicensesReducer = TUMLicensesSlice.reducer
var logoReducer = LogoSlice.reducer
var roleIDReducer = RoleIDSlice.reducer
var confirmedAccountReducer = ConfirmedAccountSlice.reducer
var partnerIDToUseReducer = partnerIDToUseSlice.reducer
var orderFilterReducer = OrderFilterSlice.reducer

// ** Export the root reducer to use ** //
const rootReducer = combineReducers({
	customerPartnerReducer,
	partnerIDReducer,
	partnerNameReducer,
	organizationIDReducer,
	accessTokenReducer,
	loggedInUserRolesPermissionsReducer,
	loggedInUserReducer,
	teamsUserDDIAssignmentReducer,
	ucPlatformUserCountReducer,
	initializeSiteThemeReducer,
	tumLicensesReducer,
	logoReducer,
	roleIDReducer,
	confirmedAccountReducer,
	partnerIDToUseReducer,
	orderFilterReducer,
	showTeamsUMReducer,
})

export default rootReducer
