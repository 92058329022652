import { Box } from '@mui/material'
import OrdersOverview from '../../../components/shared/pageShared/OrdersOverview/OrdersOverview'

function OrderManagement() {
	// Display orders overview table
	return (
		<Box id='orders'>
			<OrdersOverview />
		</Box>
	)
}

export default OrderManagement
